import React from "react";
import { Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { sidebarMenuItems } from "./helpers";

import "./styles.scss";

const { Paragraph } = Typography;

const BlogsSidebar = ({ selectedMenu = "My Dashboard", setSelectedMenu }) => {
  const renderMenuItem = (item) => {
    const { icon, activeIcon, menuItemLabel } = item;
    const isActive = selectedMenu === menuItemLabel;
    return (
      <div
        className={`account-menu-item ${isActive && "active"}`}
        onClick={() => setSelectedMenu(menuItemLabel)}
      >
        <img
          src={isActive ? activeIcon : icon}
          alt={menuItemLabel}
          width={18}
        />
        <Paragraph>{menuItemLabel}</Paragraph>
        <RightOutlined className="right-arrow" />
      </div>
    );
  };

  return (
    <div className="tajira-account-sidebar">
      {sidebarMenuItems
        .filter((opt) => opt?.icon)
        .map((item) => renderMenuItem(item))}
    </div>
  );
};

export default BlogsSidebar;
