import React from "react";
import { Menu, Radio, Space } from "antd";
import './category.scss'
const Categories = ({ categories, filtersSelection, selectedFilters }) => {
  const changeCategory = (e) => {
  };
  return (
      <Menu
        style={{
          width: 256,
        }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={categories}
      />
  );
};

export default Categories;
