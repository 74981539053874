import React from 'react'
import { ImCancelCircle } from "react-icons/im";
import './cancelpayment.scss';

export default function CancelPayment() {
  return (
    <div className='p_cancel_div'>
     <ImCancelCircle  className='p_cancel_icon'/>

        <div className='p_cancel_sorry'>Sorry</div>
        <div className='p_cancel_text'>Your payment has been canceled</div>
        
        </div>
  )
}
