import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Wishlist {
  getWishlist = () => {
    return axios
      .get(`${API_URL}api/v1/sell/public/wishlists/`, {
        headers: TOKEN
          ? {
              Authorization: `Token ${TOKEN}`,
            }
          : {},
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  wishlistItemAdd = (payload) => {
    const url = `${API_URL}api/v1/sell/public/wishlists/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  deleteWishlistItem = (id) => {
    const url = `${API_URL}api/v1/sell/public/wishlist/product/${id}/delete_wishlist_product`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  wishlistMoveToCart = (payload) => {
    const url = `${API_URL}api/v1/sell/public/wishlists/move_to_cart/`;
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `Token ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
