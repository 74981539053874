import React from "react";
import { Radio, Space } from "antd";

const Brands = ({ brands, selectedFilters, filtersSelection }) => {
  return (
    <Radio.Group
      onChange={(e) => filtersSelection("brand", e.target.value)}
      value={selectedFilters?.brand}
    >
      <Space size={15} direction="vertical">
        <Radio value="all">All Brands</Radio>
        {brands?.map((b) => (
          <Radio key={b?.id} value={b?.id}>
            {b?.name}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default Brands;
