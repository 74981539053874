import React, { useEffect, useState } from "react";
import "./singleproduct.scss";
import {
  ShoppingCartOutlined,
  HeartOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import colorselecticon from "../../../images/poshcarticon/icons8-circle-30.png";
import { InputNumber, Rate } from "antd";
import SimilarProduct from "../similarproduct/similarProduct";
import ImageSlider from "../imageSlider/imageSlider";
import { API_URL } from "../../../utils/constants";
import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";
import Spinner from "../../common/spinner";
import HowToBuy from "../../common/howToBuy/HowToBuy";
import ReturnPolicy from "../../common/returnPolicy/returnPolicy";

export default function SingleProduct() {
  const Wholesale = new API.Wholesale();
  const [loading, setLoading] = useState(false);
  const [singleProduct, setSingleProduct] = useState({});
  const [mainImage, setmainimage] = useState();
  const [imageSelectedId, setimageSelectedId] = useState();
  const [uniqueColor, setUniqueColor] = useState([]);
  const [selectcolorid, setSelectColorId] = useState();
  const [price, setPrice] = useState();
  const [priceselectid, setPriceselectid] = useState();
  const [desp, setDesp] = useState({
    description: true,
    howtobuy: false,
    returnpolicy: false,
  });

  const getSingleProduct = () => {
    getDataManager(
      Wholesale?.getSingleProduct,
      setLoading,
      {},
      "65dac691f59c176856399489"
    ).then((x) => {
      if (x?.status === true) {

        setSingleProduct(x.data);
        let colorobj = x.data.images.map((item) => item.color);
        let unique = [...new Set(colorobj)];
        setUniqueColor(unique);
        let mainimage = x.data.images.find((item) => item.mainImage == true);
        setmainimage(mainimage.image);
        setPrice(x.data.price);
        // dispatch(setInfoPage(x?.data));
      } else {
        // Messages.error({
        //   content: "Error ocurred while loading info",
        // });
      }
    });
  };

  const changeDescription = (value) => {
    if (value == 1) {
      setDesp({
        ...desp,
        description: true,
        howtobuy: false,
        returnpolicy: false,
      });
    }
    if (value == 2) {
      setDesp({
        ...desp,
        description: false,
        howtobuy: true,
        returnpolicy: false,
      });
    }
    if (value == 3) {
      setDesp({
        ...desp,
        description: false,
        howtobuy: false,
        returnpolicy: true,
      });
    }
  };
  const priceChange = (size, id) => {
    let temppriceobj = singleProduct.size_price.find(
      (item) => item.size == size
    );
    setPrice(temppriceobj.price);
    setPriceselectid(id);
  };
  const selectColor = (color, index) => {
    let tempobj = singleProduct.images.find((item) => item.color == color);
    setimageSelectedId(tempobj._id);
    setmainimage(tempobj.image);
    setSelectColorId(index);
  };
  useEffect(() => {
    getSingleProduct();
      window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <Spinner />;
  } else
    return (
      <>
        <div className="flex-container">
          <div className="box">
            <div className="left-box">
              <div className="main-image">
                <img
                  src={`${API_URL}/${mainImage}`}
                  className="main-image-src"
                  alt="main-image"
                />
              </div>
              <div className="slider-image">
                {singleProduct && singleProduct?.images?.length > 0 && (
                  <ImageSlider
                    imagesArr={singleProduct.images}
                    setmainimage={setmainimage}
                    imageSelectedId={imageSelectedId}
                    setimageSelectedId={setimageSelectedId}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="box">
            <div className="right-box">
              <div>
                <p className="rating">
                  <Rate />{" "}
                </p>
                <p className="title">{singleProduct.title}</p>
                <p className="price">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_z2ZYQJeaBWlUL1MdxHi-SOXE42vrncqGqQ&usqp=CAU"
                    width="25px"
                    height="25px"
                    style={{ translate: "0px -2px" }}
                  />
                  {price}
                </p>

                {singleProduct.productVarient == true && (
                  <h3 className="colors">
                    {" "}
                    Colors:
                    {uniqueColor &&
                      uniqueColor?.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className="color"
                            style={{
                              background: `${item}`,
                              outlineColor: `${
                                index == selectcolorid ? "black" : "#fafafa"
                              }`,
                            }}
                            onClick={() => selectColor(item, index)}
                          ></span>
                        );
                      })}
                  </h3>
                )}

                {/* <h3 className="size">
                  Size:
                  {singleProduct.priceVarient == true &&
                    singleProduct?.reseller_size_price?.length > 0 &&
                    singleProduct.reseller_size_price.map((item, index) => {
                      return (
                        <div
                          className="sp-size-text"
                          style={{
                            background: `${
                              priceselectid == index ? "#397d86" : "white"
                            }`,
                            color: `${
                              priceselectid == index ? "white" : "black"
                            }`,
                          }}
                          onClick={() => {
                            priceChange(item.size, index);
                          }}
                        >
                          {item.size}
                        </div>
                      );
                    })}
                </h3> */}

                <h3 className="sp-qty">
                  <spna style={{ marginRight: "4px" }}>Quantity:</spna>
                  <InputNumber
                    min={1}
                    max={10}
                    defaultValue={1}
                    style={{ width: "100px" }}
                  />
                </h3>

                <div className="desp">
                  <div
                    value="description"
                    onClick={() => changeDescription(1)}
                    style={{
                      // borderRight: "2px solid black",
                      background: `${
                        desp.description == true ? "#397d86" : "white"
                      }`,
                      color: `${desp.description == true ? "white" : "black"}`,
                    }}
                  >
                    Description
                  </div>{" "}
                  <div
                    name="howtobuy"
                    onClick={() => changeDescription(2)}
                    style={{
                      // borderRight: "2px solid black",
                      background: `${
                        desp.howtobuy == true ? "#397d86" : "white"
                      }`,
                      color: `${desp.howtobuy == true ? "white" : "black"}`,
                    }}
                  >
                    How To Buy
                  </div>{" "}
                  <div
                    name="returnpolicy"
                    onClick={() => changeDescription(3)}
                    style={{
                      background: `${
                        desp.returnpolicy == true ? "#397d86" : "white"
                      }`,
                      color: `${desp.returnpolicy == true ? "white" : "black"}`,
                    }}
                  >
                    Return Policy
                  </div>
                  {desp.description && (
                    <p className="desp-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: singleProduct.description,
                        }}
                      />
                    </p>
                  )}
                  {desp.howtobuy && (
                    <p className="desp-text">
                      <HowToBuy />
                    </p>
                  )}
                  {desp.returnpolicy && (
                    <p className="desp-text">
                      <ReturnPolicy />
                    </p>
                  )}
                </div>
                <div>
                  {" "}
                  <button className="cartBtn">
                    <ShoppingCartOutlined /> Add to cart
                  </button>{" "}
                  <button className="buyBtn">Buy it now</button>{" "}
                  {/* <button className="wishBtn">
                  <HeartOutlined /> Wishlist
                </button>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="similar-product" style={{ gap: "5px" }}>
              {/* <SimilarProduct Similar_product_name="Similar items" /> */}
            </div>
          </div>
        </div>
      </>
    );
}
