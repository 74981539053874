/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, message, Pagination, Collapse, Space, Button } from "antd";
import {
  AppstoreFilled,
  MenuOutlined,
  CaretRightOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import BlockMode from "../../common/block-mode";
import ListMode from "../../common/list-mode";
import ArabsToolsBreadcrumb from "../../common/breadcrumb";
import SideBar from "../../common/sidebar";
import Spin from "../../common/spinner";
import FilterBody from "../../common/filter-detail";

import Brands from "./brands";
import Categories from "./categories";
import Rating from "../../common/rating";
import PriceSlider from "../../common/price-slider";

import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";

import "./styles.scss";
import ProductNotFound from "../product-not-found";
import WholeSellerProduct from "../../wholeSeller/product/product";

const { Panel } = Collapse;

const defaultPaginationProps = {
  current: 1,
  defaultPageSize: 50,
  pageSizeOptions: [10, 25, 50, 100],
  hideOnSinglePage: true,
  showSizeChanger: true,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

const SearchResults = () => {
  const product = new API.Products();
  const category = new API.Category();

  const location = useLocation();
  const params = useParams();

  const { brands, categories } = useSelector((state) => {
    return state?.app;
  });

  const searchParam = location?.search || "";
  const path = location?.pathname;

  const splittedSearchParam = (searchParam && searchParam.split("?")) || [];

  const name = splittedSearchParam[1];

  const { brandId, categoryId, subCategory } = params;

  const [viewMode, setViewMode] = useState("grid");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [heading, setHeading] = useState([]);
  const [searchParams, setSearchParams] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [showFilterDetail, setShowFilterDetail] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 50,
    hideOnSinglePage: true,
    pageSizeOptions: [10, 25, 50, 100],
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  useEffect(() => {
    const categoryName = categoryDetails?.name;
    const subCategoryName = (categoryDetails?.children || []).map(
      (c) => c.name
    );

    if (path.includes("search-results")) {
      setHeading(["Search Results"]);
      if (location.search) {
        fetchProductsList(`?name=${name}` + `&limit=50&offset=0`);
      }
      setSearchParams(`?name=${name}`);
    }

    if (path.includes("brand")) {
      setHeading(["Brand"]);
      fetchProductsList(
        location?.search
          ? location?.search + `?name=${brandId}` + `&limit=50&offset=0`
          : `?brand=${brandId}` + `&limit=50&offset=0`
      );
      setSearchParams(
        location?.search
          ? location?.search + `?name=${brandId}`
          : `?brand=${brandId}`
      );
    }

    if (path.includes("category") && categoryDetails?.id) {
      setHeading(
        subCategory ? [categoryName, ...subCategoryName] : [categoryName]
      );
      const id = categoryDetails?.id;
      fetchProductsList(
        location.search !== ""
          ? location.search + `?category=${id}` + `&limit=50&offset=0`
          : `?category=${id}` + `&limit=50&offset=0`
      );
      setSearchParams(
        location.search !== ""
          ? location.search + `?category=${id}`
          : `?category=${id}`
      );
    }
  }, [categoryDetails?.id, location.search, brandId]);

  useEffect(() => {
    if (categoryId) {
      fetchCategoryDetails();
    }
  }, [categoryId]);

  const fetchCategoryDetails = () => {
    getDataManager(category?.getCategoryDetails, setLoading, categoryId).then(
      (x) => {
        if (x?.status === "success") {
          setCategoryDetails(x?.data[0]);
        } else {
          message.error({
            content: "Error ocurred while loading category details",
          });
        }
      }
    );
  };

  const fetchProductsList = (params, pagePayload = {}) => {
    getDataManager(product?.getProducts, setLoading, params).then((x) => {
      if (x?.data?.results.length > 0) {
        setPagination({
          ...pagination,
          current: pagePayload?.current || pagination?.current,
          pageSize: pagePayload?.pageSize || pagination?.defaultPageSize,
          total: x?.data?.count,
        });
        setProducts(x?.data?.results);
      } else {
        setProducts([]);
        setPagination(defaultPaginationProps);
      }
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    const pagePayload = {
      offset,
      limit,
      current: page,
      pageSize: pageSize,
    };
    fetchProductsList(
      searchParams + `&limit=${limit}&offset=${offset}`,
      pagePayload
    );
  };

  const filtersSelection = (name, value) => {
    setSelectedFilters({ ...selectedFilters, [name]: value });
  };

  const fetchFilteredProducts = (query) => {
    setSearchParams(query);
    fetchProductsList(query, defaultPaginationProps);
  };

  const handleApplyFilter = () => {
    let queryStr = [];
    Object.keys(selectedFilters).forEach((item) => {
      if (Array.isArray(selectedFilters[item])) {
        queryStr = [
          ...queryStr,
          item.toLowerCase() + "=" + selectedFilters[item].join(","),
        ];
      } else {
        queryStr = [
          ...queryStr,
          item.toLowerCase() + "=" + selectedFilters[item],
        ];
      }
    });
    const query = queryStr.join("&");
    fetchFilteredProducts("?" + query);
  };

  const handleClearFilter = () => {
    setSelectedFilters({});
    fetchProductsList();
  };

  return (
    <>
      <ArabsToolsBreadcrumb paths={heading} />
      {loading && <Spin />}
      {name && <div className="page-title">Search Results for "{name}"</div>}
      <div className="tajira-particular-container">
        <SideBar>
          <Card className="card-shadow filters-sidebar">
            <Collapse
              defaultActiveKey={["1"]}
              ghost
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Panel header="Select Category" key="1">
                <Categories
                  categories={categories}
                  filtersSelection={filtersSelection}
                  selectedFilters={selectedFilters}
                />
              </Panel>
              <Panel header="Select Brand" key="2">
                <Brands
                  brands={brands}
                  filtersSelection={filtersSelection}
                  selectedFilters={selectedFilters}
                />
              </Panel>
              <Panel header="Price" key="3">
                <PriceSlider
                  filtersSelection={filtersSelection}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </Panel>
              <Panel header="Rating" key="4">
                <Rating
                  filtersSelection={filtersSelection}
                  selectedFilters={selectedFilters}
                />
              </Panel>
            </Collapse>
            <br />
            <Space
              direction="vertical"
              className="w-100"
              style={{ padding: "0 20px 20px 20px" }}
              size={15}
            >
              <Button
                type="primary"
                block
                shape="round"
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
              <Button
                type="default"
                block
                shape="round"
                onClick={handleClearFilter}
              >
                Reset Filter
              </Button>
            </Space>
          </Card>
        </SideBar>
        <section className="main-body">
          <div className="view-mode-container">
            <Space>
              <AppstoreFilled
                onClick={() => setViewMode("grid")}
                className={viewMode === "grid" ? "selected-mode" : "mode"}
              />
              <MenuOutlined
                onClick={() => setViewMode("list")}
                className={viewMode === "list" ? "selected-mode" : "mode"}
              />
            </Space>
            <div className="filters-toggler">
              <Button
                type="primary"
                onClick={() => {
                  setShowFilterDetail(!showFilterDetail);
                }}
              >
                <SlidersOutlined /> Filter
              </Button>
            </div>
          </div>
          {showFilterDetail && (
            <FilterBody
              categories={categories}
              brands={brands}
              filtersSelection={filtersSelection}
              selectedFilters={selectedFilters}
              handleApplyFilter={handleApplyFilter}
              handleClearFilter={handleClearFilter}
              setSelectedFilters={setSelectedFilters}
            />
          )}
          {/* {products.length > 0 ? (
            <>
              {viewMode === "grid" ? (
                <BlockMode products={products} />
              ) : (
                <ListMode products={products} />
              )}
              <div className="shop-pagination-container">
                <Pagination onChange={handlePaginationChange} {...pagination} />
              </div>
            </>
          ) : (
            <ProductNotFound />
          )} */}
          <WholeSellerProduct/>
        </section>
      </div>
    </>
  );
};

export default SearchResults;
