/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Input, notification, Radio, Space } from "antd";
import "./styles.scss";
import { getDataManager, validateNumber } from "../../../utils/helper.functions";
import API from "../../../utils/api";
import Spinner from "../../common/spinner";

const Checkout = ({setShippingCharge}) => {
  const [location, setLocaiton] = useState(1);
  const [district, setDistrict] = useState("Dhaka");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState();
  const [mobile, setMobile] = useState("");
  const [orderNote, setOrderNote] = useState();
  const [paymentType, setpaymentType] = useState(1);
  const [amount , setamount] = useState("80");
  const [loading, setLoading] = useState(false);
  const Reseler =new API.Reseler();

  const onChange = (e) => {
    if (e.target.value == 1) {
      setDistrict("Dhaka");
      setShippingCharge(80);
      setamount(80)
    } else {
      setDistrict("");
      setShippingCharge(130);
      setamount(130)
    }
    setLocaiton(e.target.value);
  };

  const onChangePaymenttype = (e) => {
    if (e.target.value == 1) {
      setpaymentType(1)
    } else {
      setpaymentType(2)
    }
  };

  const Notification = (type, mesg, desp) => {
    notification[type]({
      message: mesg,
      description: desp,
    });
  };

  const errors = () => {
    if (district == "") {
      Notification("error", "District can not be empty");
      return false;
    }
    if (address == "") {
      Notification("error", "Address can not be empty");
      return false;
    }
    if (mobile == "") {
      Notification("error", "Mobile number can not be empty");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (errors()) {
    } else {
      return;
    }
    const payload = {
      shippingAt: location==1 ? "inside_dhaka" : "outside_dhaka",
      shippingAddress: {
        house: address,
        district: district,
        postcode: postcode,
        mobile: mobile,
      },
      order_note: orderNote,
      payment_type:paymentType == 1 ? "partial" : "full",
      amount: paymentType == 1 ? amount : "0",
    };


    if(location==1  && paymentType != 2)
    {
       if(amount < 80) {
        Notification("error", "Amount should be at least 80tk");
         return
       } 
    }

    if(location==2 && paymentType != 2)
      {
         if(amount < 130) {
          Notification("error", "Amount should be at least 130tk");
           return
         } 
      }


    getDataManager(Reseler?.checkoutproduct, setLoading, payload).then((x) => {
     if(x?.status == true)
     {  
      
      localStorage.setItem('shopyfastorderid', `${x?.data?.order_id}`);

      window.location.href = `${x?.data?.payment_url}`;
      
      Notification("success", "Order confirmed");

    }
    });

  };


  if(loading)
    return <Spinner/>

  return (
    <>
      <div style={{border:".5px solid grey", marginTop:"20px"}}>
        <div style={{textAlign:"center", padding:"30px", fontSize:"30px", fontWeight: 600 }}>Checkout</div>
        <div className="check_main_div">
          <div className="check_div">
            <span style={{textAlign:"left", marginRight:"60px"}}>
            <div>Location</div>
            <Radio.Group onChange={onChange} value={location}>
              <Space direction="vertical">
                <Radio value={1}>Inside Dhaka</Radio>
                <Radio value={2}>Outside Dhaka</Radio>
              </Space>
            </Radio.Group>
            </span>
          </div>

          <div className="check_div">
            <span style={{textAlign:"left" }}>
            <div>Payment Type</div>
            <Radio.Group onChange={onChangePaymenttype} value={paymentType}>
              <Space direction="vertical">
                <Radio value={1}>Partial</Radio>
                <Radio value={2}>Full</Radio>
              </Space>
            </Radio.Group>
            </span>
          </div>


          {paymentType == 1 && (
            <div className="check_div">
              <div>Amount</div>
              <Input
                value={amount}
                onChange={(e) => {
                  const positiveNumberRegex =  /^\s*$|^\d+$/;

                  if(positiveNumberRegex.test(e?.target?.value))
                  {
                    setamount(e?.target?.value)
                  } 

                }}
                style={{color:"black", fontWeight:"bold"}}
                className="check_inputs"
                disabled
              />
            </div>
          )}

          <div className="check_div">
            <div>Address</div>
            <textarea
              value={address}
              onChange={(e) => {
                setAddress(e?.target?.value);
              }}
              className="check_inputs"
            />
          </div>

          {location == 2 && (
            <div className="check_div">
              <div>District</div>
              <Input
                value={district}
                onChange={(e) => {
                  setDistrict(e?.target?.value);
                }}
                className="check_inputs"
              />
            </div>
          )}

          <div className="check_div">
            <div>Postcode</div>
            <Input
              value={postcode}
              onChange={(e) => {
                setPostcode(e?.target?.value);
              }}
              className="check_inputs"
            />
          </div>

          <div className="check_div">
            <div>Mobile</div>
            <Input
              value={mobile}
              onChange={(e) => {
                setMobile(e?.target?.value);
              }}
              className="check_inputs"
            />
          </div>

          <div className="check_div">
            <div>Order Note</div>
            <textarea
              value={orderNote}
              onChange={(e) => {
                setOrderNote(e?.target?.value);
              }}
              className="check_inputs"
            />
          </div>

        </div>

        <div
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleSubmit}>Confirm Order</Button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
