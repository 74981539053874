import React from "react";
import "./similarProduct.scss";
import Slider from "react-slick";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { GrStar } from "react-icons/gr";
import ResellerCard from "./rslrCard";

export default function SimilarProduct({
  Similar_product_name = "Random Product",
}) {
  const slider = React.useRef(null);
  const imageUrls = Array.from(
    { length: 30 },
    (_, index) => `https://picsum.photos/200/300/?image=${index + 1}`
  );

  let arr = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
  ];

  var settings = {
    className: "react-slick",
    // dots: true,
    infinite: false,
    initialSlide: 0,
    infinite: true,
    // autoplay: true,
    speed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          speed: 4000,
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          speed: 4000,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          speed: 3000,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
          speed: 2000,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 2000,
        },
      },
    ],
  };

  return (
    <div style={{ marginBottom: "50px", marginLeft: "15px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4
          style={{
            fontWeight: "bold",
            margin: "0px",
            fontFamily: "Rubik",
            fontSize: "1.3rem",
            translate: "0px -5px",
            fontFamily: "sans-serif",
          }}
        >
          {Similar_product_name}
        </h4>
        <div style={{ translate: "-23px 0px" }}>
          <button
            onClick={() => slider?.current?.slickPrev()}
            style={{
              background: "none",
              border: "none",
              fontSize: "12px",
              border: "2px solid #397D86",
              borderRadius: "350px",
              marginRight: "3px",
            }}
          >
            <AiOutlineArrowLeft style={{ translate: "0px 1px" }} />
          </button>
          <button
            onClick={() => slider?.current?.slickNext()}
            style={{
              background: "none",
              border: "none",
              fontSize: "12px",
              border: "2px solid #397D86",
              borderRadius: "35px",
              margin: "0px",
            }}
          >
            <AiOutlineArrowRight style={{ translate: "0px 1px" }} />
          </button>
        </div>
      </div>

      <Slider ref={slider} {...settings}>
        {imageUrls.map((item) => {
          return (
            <div>
              <ResellerCard item={item} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
