import axios from "axios";
import { API_URL, TOKEN, RSTokenfunc, CSTokenfunc } from "../constants";

export default class Landing {
    getLandingProduct = (payload, params) => {
        const url = `${API_URL}/api/wholesaler/product/website?page=${params?.pageno}&size=${params?.size}`;
        return axios
          .get(url)
          .then(function(response) {
            if (response.status === 200 || response.status === 201) {
              return response.data;
            } else {
              return {
                data: {
                  results: [],
                },
              };
            }
          })
          .catch(function(error) {
            return error?.response?.data;
          });
      };

}