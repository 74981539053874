/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Pagination, message, Card, Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";

import ArabsToolsBreadcrumb from "../../common/breadcrumb";

import Spinner from "../../common/spinner";

import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";

import "./styles.scss";

const { Title } = Typography;

const defaultPaginationProps = {
  current: 1,
  pageSize: 50,
  pageSizeOptions: [10, 25, 50, 100],
  hideOnSinglePage: true,
  showSizeChanger: true,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
};

const AllShops = () => {
  const store = new API.Products();

  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [pagination, setPagination] = useState(defaultPaginationProps);

  useEffect(() => {
    fetchStoresList();
  }, []);

  const fetchStoresList = (payload) => {
    getDataManager(store?.getStoreList, setLoading, payload).then((x) => {
      if (x?.data?.results.length > 0) {
        setPagination({
          ...pagination,
          current: payload?.current || pagination?.current,
          pageSize: payload?.pageSize || pagination?.pageSize,
          total: x?.data?.count,
        });
        setStores(x?.data?.results);
      } else {
        setStores([]);
        message.error({ content: "No store found!", duration: 3 });
      }
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;
    const payload = {
      offset,
      limit,
      current: page,
      pageSize: pageSize,
    };

    fetchStoresList(payload);
    setPagination(pagination);
  };

  return (
    <section className="tajira-all-shops">
      {loading && <Spinner />}
      <ArabsToolsBreadcrumb paths={["All Shops"]} />
      <div className="main-heading" level={4}>
        All Shops
      </div>
      <br />
      <Row gutter={[20, 20]}>
        {stores?.map((shop) => (
          <Col key={shop?.id} xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
            <Link to={`/shop/${shop?.id}`}>
              <Card className="shop-card">
                <img src={shop?.cover} alt="" />
                <Title level={5}>{shop?.name}</Title>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <div className="shop-pagination-container">
        <Pagination onChange={handlePaginationChange} {...pagination} />
      </div>
    </section>
  );
};

export default AllShops;
