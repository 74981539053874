import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./styles.scss";

const App = ({ showModal, children, title, closeModal }) => {
  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      visible={showModal}
      maskClosable={false}
      className="tajira-base-modal"
      closeIcon={<CloseOutlined />}
      title={title}
      onCancel={closeModal}
    >
      {children}
    </Modal>
  );
};

export default App;
