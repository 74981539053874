import React from 'react'
import { BsCartXFill, BsCartPlus } from "react-icons/bs";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { GrStar } from "react-icons/gr";
import './Card1.scss';
export default function Card1({item}) {
  return (
    <>
      <div class="main-product-card">
        <div class="main-product-img">
          <img src={item} alt="" />
        </div>

        <div class="main-product-info">
          <div className="main-product-1stdiv">
            <div className="main-product-sold-rating">
              {" "}
              <span className="main-product-sold">141 sold </span>
              <span className="main-product-rating">
                | <GrStar style={{ translate: "0px 2px", color: "grey" }} /> 4.1
              </span>
            </div>

            <div class="main-icon-container">
              <BsCartPlus className="main-icon" />
              {/* <BsCartXFill className="main-icon"  />
    <AiTwotoneHeart className="main-icon" /> */}
              <AiOutlineHeart className="main-icon" />
            </div>
          </div>
          <h2 class="main-product-title">Nike Shoes</h2>
          {/* <p class="product-description">High-quality Nike shoes for your active lifestyle.</p> */}
          <p class="main-product-price">
            <span className="actual-cost">$129.99</span>{" "}
            <span className="discount-cost">$29.99</span>
          </p>
        </div>
        <div className="main-offer"> -26% </div>
      </div>
    </>
  )
}
