/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Rate, Button, Space, Typography } from "antd";
import { HeartOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import BaseModal from "../../../common/base-modal";
import ArabToolsCarousel from "../../../common/carousel";

import { setShowLoginModal } from "../../../../utils/redux/actions/actions";

import "./styles.scss";

const { Title } = Typography;

const QuickView = (props) => {
  const {
    product,
    showModal,
    closeModal,
    handleAddToWishlist,
    handleRemoveFromWishlist,
    handleAddToCart,
    setIsFavourite,
    isFavourite,
  } = props;

  const [carouselRef, setCarouselRef] = useState({});
  const [previewImage, setPreviewImage] = useState({
    id: 1,
    img: (product?.images || [])[0]?.file || product?.image,
  });

  const getRefsFromChild = (childRefs) => {
    setCarouselRef(childRefs);
  };

  const onPrev = () => carouselRef.slidePrev();

  const onNext = () => carouselRef.slideNext();

  const configs = [
    { width: 400, itemsToShow: 3, itemsToScroll: 3 },
    { width: 550, itemsToShow: 3, itemsToScroll: 3 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1450, itemsToShow: 3, itemsToScroll: 3 },
  ];

  return (
    <BaseModal showModal={showModal} closeModal={closeModal} title="Quick View">
      <div className="tajira-quickview">
        <section className="quickview-images-section">
          {product?.images?.length > 4 ? (
            <div className="small-images">
              <LeftOutlined onClick={onPrev} />
              <ArabToolsCarousel
                configs={configs}
                getRefsFromChild={getRefsFromChild}
              >
                {(product?.images || []).map((img) => (
                  <img
                    key={img?.id}
                    className={
                      previewImage.id === img?.id ? "g-img current" : "g-img"
                    }
                    src={img?.file}
                    alt="sub-product"
                    onClick={() =>
                      setPreviewImage({
                        id: img?.id,
                        img: img?.file,
                      })
                    }
                  />
                ))}
              </ArabToolsCarousel>
              <RightOutlined onClick={onNext} />
            </div>
          ) : (
            (product?.images || []).length > 0 && (
              <div className="small-images">
                {(product?.images || []).length > 0 &&
                  product?.images.map((img) => (
                    <img
                      key={img?.id}
                      className={
                        previewImage.id === img?.id ? "g-img current" : "g-img"
                      }
                      src={img?.file}
                      alt="sub-product"
                      onClick={() =>
                        setPreviewImage({
                          id: img?.id,
                          img: img?.file,
                        })
                      }
                    />
                  ))}
              </div>
            )
          )}
          <div className="main-image">
            <img src={previewImage.img} alt="main-product" />
          </div>
        </section>
        <section className="quickview-description-section">
          <div className="quickview-title">{product.name}</div>
          <div className="quickview-price-and-review">
            <Rate value={Number(product.rating)} disabled allowHalf />
            <span className="pink-text">({product?.rating})</span>
            <span className="grey-text">
              ({product?.total_reviews} customer review)
            </span>
          </div>
          <Title level={5}>Price</Title>
          <Price
            product={product}
            handleAddToCart={handleAddToCart}
            handleAddToWishlist={handleAddToWishlist}
            handleRemoveFromWishlist={handleRemoveFromWishlist}
            setIsFavourite={setIsFavourite}
            isFavourite={isFavourite}
          />
          <Button
            // disabled={actionDisabled}
            onClick={() => handleAddToCart("buy_now")}
            className="buy-now-btn"
            type="primary"
            block
          >
            Buy Now
          </Button>
        </section>
      </div>
    </BaseModal>
  );
};

export default QuickView;

const Price = ({
  product,
  isFavourite,
  handleAddToWishlist,
  handleAddToCart,
  handleRemoveFromWishlist,
}) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.app);

  const handleWishlist = () => {
    if (isLoggedIn) {
      if (isFavourite) {
        handleRemoveFromWishlist();
      } else {
        handleAddToWishlist();
      }
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  return (
    <section className="quickview-price-container">
      <div className="price">BDT {product?.new_price}</div>
      <Space size={20}>
        <Button
          type="primary"
          shape="rounded"
          className="add-to-cart-btn"
          onClick={handleAddToCart}
        >
          Add To Cart
        </Button>
        {isFavourite ? (
          <div className="added-to-wishlist">
            <HeartOutlined onClick={handleWishlist} />
          </div>
        ) : (
          <div className="add-to-wishlist">
            <HeartOutlined onClick={handleWishlist} />
          </div>
        )}
      </Space>
    </section>
  );
};