import React, { useState } from "react";
import { Space, Input, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import companyLogo from "../../images/shpyfstbd.png";

// import companyLogo from "../../images/footer-logo.png";
import addressIcon from "../../images/pin.png";
import contactIcon from "../../images/contact.png";
import emailReply from "../../images/email-reply.png";
import goTop from "../../images/goTop.png";
import payment from "../../images/new pymnts logo.png";

import API from "../../utils/api";
import { getDataManager } from "../../utils/helper.functions";

import "./styles.scss";
import { API_URL } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const { rsshopdata } = useSelector((state) => {
    return state?.app;
  });
  const { infoPage } = useSelector((state) => {
    return state?.app;
  });

  const topFunction = () => {
    const ele = document.getElementById("base-component");
    if (ele) {
      ele.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <footer className="tajira-footer-base-container">
        <Space align="start" wrap className="items-container" size={[20, 40]}>
          <BasicInfo infoPage={rsshopdata} />
          <Policies infoPage={rsshopdata} />
          {/* <QuickLinks infoPage={rsshopdata} /> */}
          {/* <MyAccount /> */}
          <ContactUs infoPage={rsshopdata} />
        </Space>
        <img
          className="top-up"
          width="50px"
          src={goTop}
          alt=""
          onClick={topFunction}
        />
      </footer>
      <div className="footer-lower-belt">
        <div>Copyright © 2024 www.shopyfastbd.com</div>
        <img src={payment} alt="" height={35} width={200} />
      </div>
    </>
  );
};

export default Footer;

const BasicInfo = ({ infoPage }) => {
  const { logo } = useSelector((state) => {
    return state?.app;
  });

  const path = useHistory();
  let linktext = path.location.pathname.split('/')[1];
  let logotext = linktext.toUpperCase();
  let message = "whatsapp number";
  // const whatsappURL = `https://api.whatsapp.com/send?phone="+8801679706957"&text=${encodeURIComponent(message)}`;
  const whatsappURL = `https://api.whatsapp.com/send?phone=88${infoPage?.contact_number}&text=whatsapp+number&fbclid=IwY2xjawExzM5leHRuA2FlbQIxMAABHUcgpTZFip-IzCdmEBMlc8fIAnq-z9Vmwqi8LUsAQFrY-3gn4_tclReOqg_aem_aOiAxLHVorB2g-T8nQvbtg`
  // ${infoPage?.contact_number}
  return (
    <section className="footer-basicinfo-container">
      <Link to="/">
        

        {logo ? (
          <img className="company-logo" src={`${API_URL}/${logo}`} alt="logo" style={{marginBottom:"20px"}}/>
        ) : (
          <span
            style={{
              fontSize: "25px",
              fontWeight: "750",
              letterSpacing: ".5px",
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            {logotext}
          </span>
        )}

      </Link>
      <Space direction="vertical" size={15} align="start">
        <a href={whatsappURL} className="row">
          <img width={20} src={contactIcon} alt="" />
          {infoPage?.contact_number}
        </a>
        <a href={`mailto:${infoPage?.email}`} className="row">
          <img width={20} src={emailReply} alt="" />
          {infoPage?.email}
        </a>
        <div className="row">
          <img width={20} src={addressIcon} alt="" />
          {infoPage?.business_address}
        </div>
      </Space>
    </section>
  );
};

const MyAccount = () => {
  return (
    <section className="footer-my-account-container">
      <div className="header">My Account</div>
      <Space direction="vertical" size={5} align="start">
        <Link className="content" to="/account/my-account">
          Account
        </Link>
        <Link className="content" to="/account/followingShops">
          Following Shops
        </Link>
        <Link className="content" to="/account/myOrders">
          My Orders
        </Link>
        <Link className="content" to="/shopping-cart">
          Cart
        </Link>
        <Link className="content" to="/account/wishlist">
          Wishlist
        </Link>
      </Space>
    </section>
  );
};

const QuickLinks = () => {
  return (
    <section className="footer-my-letushelp-container">
      <div className="header">Quick Links</div>
      <Space direction="vertical" size={5} align="start">
        <Link to="/about-us" className="content">
          Seller registration via App
        </Link>
        <Link to="/blogs" className="content">
          Blogs
        </Link>
        <Link to="/contact-us" className="content">
          Contact Us
        </Link>
        <Link className="content" to="/brands">
          Brands
        </Link>
        <Link to="/campaigns" className="content">
          Compaign
        </Link>
      </Space>
    </section>
  );
};

const Policies = () => {
  return (
    <section className="footer-my-policies-container">
      <div className="header">Policies</div>
      <Space direction="vertical" size={5} align="start">
        <Link to="/about-us" className="content">
          About Us
        </Link>
        <Link to="/terms-and-conditions" className="content">
          Term & Policy
        </Link>
      </Space>
    </section>
  );
};

const ContactUs = () => {
  const home = new API.Home();

  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const subscribeToEmail = () => {
    if (email !== "") {
      if (validateEmail(email)) {
        message.loading({
          content: "subscribing...",
          duration: 0,
          key: "loader",
        });
        const payload = { email };
        getDataManager(home?.subscribeToEmail, null, payload).then((x) => {
          if (x?.status === "success") {
            message.success({
              content: "Subscribe to email request submitted",
              duration: 3,
              key: "loader",
            });
          } else {
            message.error({
              content: "Process failed to submit request",
              duration: 3,
              key: "loader",
            });
          }
        });
      } else {
        message.warning({
          content: "Email is not valid",
          duration: 3,
          key: "loader",
        });
      }
    } else {
      message.warning({
        content: "Please enter email",
        duration: 3,
        key: "loader",
      });
    }
  };

  return (
    <section className="footer-contactus-container">
      <Space direction="vertical" size={15} align="start">
        <div style={{ fontWeight: 500, fontSize: "13pt" }}>Subscribe Now</div>
        {/* <div>
          Register to Tajira newsletter now to get updates
          <br /> on offer, promotion and many more.
        </div> */}

        <div className="email-letter-input-container">
          <Input
            className="email-letter-input"
            placeholder="Your email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <div
            style={{
              background: "#ec008c",
              color: "#fff",
              padding: "10px 25px",
              borderRadius: "0 8px 8px 0",
              fontSize: "16px",
            }}
            onClick={subscribeToEmail}
          > */}
          {/* <SendOutlined /> */}
          {/* </div> */}
        </div>
      </Space>
    </section>
  );
};
