import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./landingPage.scss";
import SimilarProduct from "../similarproduct/similarProduct";
import Whyshopwithus from "../../reseller/whyshopwithus/whyshopwithus";
import Category from "./Category";
import API from "../../../utils/api";
import { getDataManager } from "../../../utils/helper.functions";
import { useDispatch, useSelector } from "react-redux";
import {
  setRSCategory,
  setRSLogo,
  setRSShopData,
  setRSSiteInfo,
} from "../../../utils/redux/actions/actions";
import Spinner from "../../common/spinner";
import image1 from "../../../images/banner/black fiday banner.png";
import image2 from "../../../images/banner/online-fashion-shopping-with-computer.jpg";
import image3 from "../../../images/banner/photocomposition-horizontal-online-shopping-banner (3).jpg";
import image4 from "../../../images/banner/photocomposition-horizontal-online-shopping-banner.jpg";
import image5 from "../../../images/banner/photocomposition-horizontal-shopping-banner-with-woman-big-smartphone.jpg";
import image6 from "../../../images/banner/up to.png";
import beauti from "../../../images/category/beauty.jpg";
import clothing from "../../../images/category/clothing.jpg";
import electronics from "../../../images/category/electronics.jpg";
import menfashion from "../../../images/category/menfashion.jpg";
import sports from "../../../images/category/sports.jpg";
import tvhome from "../../../images/category/tv-home.jpg";
import { API_URL } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import CustomNotification from "../../common/custom-notification/customNotification";
import { SinglepageDetails } from "../../reseller/rsSingleproduct/rsSingleProduct";
import SliderProduct from "../similarproduct/similarProduct";

export default function LandingPage({ shopName }) {

  var intervalId
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "landing-react-slick",
    pauseOnHover: true

  };
  const [loading, setLoading] = useState(false);
  const [homePageData, setHomePageData] = useState();
  const [shopInfo, setShopInfo] = useState();
  const [banner, setBanner] = useState([]);
  const [CustomNotificationdata, setCustomNotificationdata] = useState({
    toggle: false,
    title: "",
    body: "",
    imageUrl: "",
  });

  const [allparams, setParams] = useState({
    pageno: "1",
    size: "20",
    category: "",
    owner: "",
    max_price: 10000,
    min_price: 1,
    sort_by: "price_asc",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const { showLoginModal, isLoggedIn } = useSelector((state) => {
    return state?.app;
  });

  const Reseler = new API.Reseler();

  const getReselershopinfo = async () => {
    let shopdata = await getDataManager(
      Reseler?.getReselershopinfo,
      setLoading,
      {},
      shopName
    );
    if (shopdata?.status == true) {
      // dispatch(setRSSiteInfo());
    }


    return shopdata;
  };

  const getReselerHomePageProduct = async (id) => {
    let homepageproduct = await getDataManager(
      Reseler?.getReselerHomePageProduct,
      setLoading,
      {},
      id
    );
    setHomePageData(homepageproduct);
  };

  const handleNavigate = (e) => {
    history.push(`/${shopInfo?.data?.shop_data?.shop_url}/product?category=${e}`)
  }

  const handlebannernavigation = (item) => {
    window.open(item?.link, '_blank');
  }

  let bannerarray = [
    { image: image1, flag: false },
    { image: image2, flag: false },
    { image: image3, flag: false },
    { image: image4, flag: false },
    { image: image5, flag: false },
  ];

  const [productData, setProductData] = useState([]);

  const getReselerAllProduct = async (params, id) => {
    let productdata = await getDataManager(
      Reseler?.getReselerAllProduct,
      setLoading,
      {},
      id,
      params
    );

    if (productdata?.status == true) {
      setProductData(productdata?.data?.data);
      randomnotificationshow();
    }
  };

  const randomnotificationshow = () => {

    intervalId = setInterval(() => {
      RandomNotification();
    }, 15000);
  };
  var tempbool = false;

  const RandomNotification = () => {
    let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
    let randomn = Math.floor(Math.random() * (10 - 0)) + 0;
    let names = ["Mamun", "Rafi", "Rubel", "Rasel", "Rahul", "Rimi", "Ritu", "Nili", "Ruman", "Mahi", "Mitu"];
    let togg = [false, true, false, true, false];
    const product = productData[randomp];
    const randomtogg = togg[randomn];

    const randomname = names[randomn];

    if (tempbool === randomtogg) {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle: !randomtogg,
        name: randomname
      });
      tempbool = !randomtogg
    }
    else {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle: randomtogg,
        name: randomname
      });
      tempbool = randomtogg

    }
  };

  let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
  const notiproduct = productData[randomp];

  useEffect(async () => {
    let shopdata = await getReselershopinfo();
    setShopInfo(shopdata);
    dispatch(setRSLogo(shopdata?.data?.shop_data?.shop_logo));
    dispatch(setRSShopData(shopdata?.data?.shop_data));
    dispatch(setRSCategory(shopdata?.data?.category));

    if (shopdata?.data?.shop_data?.banner?.length > 0) {
      setBanner(shopdata?.data?.shop_data?.banner);
    } else setBanner(bannerarray);

    let homepagedata = await getReselerHomePageProduct(
      shopdata?.data?.shop_data?._id
    );

    getReselerAllProduct(allparams, shopdata?.data?.shop_data?._id);

    return () => {
      clearInterval(intervalId);
    };

  }, []);


  if (loading) return <Spinner />;

  return (
    <div>
      {CustomNotificationdata?.toggle && (

        <CustomNotification product={notiproduct} name={CustomNotificationdata?.name} />

      )}

      <div className="banner_category_lnding_page">
        <div className="landing_page_category">
          <div className="single_category" onClick={() => { handleNavigate("Electronic Accessories") }}>
            <img src={electronics} alt="fsdfd" className="cat_image" />
            Electronic Accessories</div>
          <div className="single_category" onClick={() => { handleNavigate("TV - Home Appliances") }}>
            <img src={tvhome} alt="fsdfd" className="cat_image" />
            TV & Home Appliances</div>

          <div className="single_category" onClick={() => { handleNavigate("Health - Beauty") }}>
            <img src={beauti} alt="fsdfd" className="cat_image" />
            Health & Beauty</div>

          <div className="single_category" onClick={() => { handleNavigate("Clothing") }}>
            <img src={clothing} alt="fsdfd" className="cat_image" />
            Clothing</div>

          <div className="single_category" onClick={() => { handleNavigate("Men's Fashion") }}>
            <img src={menfashion} alt="fsdfd" className="cat_image" />
            Men's Fashion</div>
          <div className="single_category" onClick={() => { handleNavigate("Sport - Outdoors") }}>
            <img src={sports} alt="fsdfd" className="cat_image" />
            Sport & Outdoors</div>
        </div>


       

        <Slider {...settings}>
          {banner?.map((item, index) => {
            return (
              <>
                <div>
                  <h3>
                    {item.flag == false ? (
                      <img src={item.image} alt="banner" className="image" />
                    ) : (
                      <img
                        src={`${API_URL}/${item.image}`}
                        alt="banner"
                        className="image"
                        onClick={() => { handlebannernavigation(item); }}
                      />
                    )}
                  </h3>
                </div>
              </>
            );
          })}
        </Slider>


        <div className="landing_page_category_horizontal">
          <div className="single_category" onClick={() => { handleNavigate("Electronic Accessories") }}>
            <img src={electronics} alt="fsdfd" className="cat_image" />
            <p>Electronic <br/>Accessories</p></div>
          <div className="single_category" onClick={() => { handleNavigate("TV - Home Appliances") }}>
            <img src={tvhome} alt="fsdfd" className="cat_image" />
            <p>TV & Home <br/>Appliances</p></div>

          <div className="single_category" onClick={() => { handleNavigate("Health - Beauty") }}>
            <img src={beauti} alt="fsdfd" className="cat_image" />
            <p>Health & Beauty</p></div>

          <div className="single_category" onClick={() => { handleNavigate("Clothing") }}>
            <img src={clothing} alt="fsdfd" className="cat_image" />
            <p>Clothing</p></div>

          <div className="single_category" onClick={() => { handleNavigate("Men's Fashion") }}>
            <img src={menfashion} alt="fsdfd" className="cat_image" />
           <p> Men's Fashion </p></div>
          <div className="single_category" onClick={() => { handleNavigate("Sport - Outdoors") }}>
            <img src={sports} alt="fsdfd" className="cat_image" />
           <p> Sport & Outdoors </p></div>
        </div>



      </div>
      {/* <Category /> */}

      {/* <div className="single-page-slider"> */}
        <SliderProduct
          Similar_product_name="Best Selling"
          products={
            homePageData?.data?.best_selling?.length > 0
              ? homePageData?.data?.best_selling
              : []
          }
        />
      {/* </div> */}

      {/* <div className="single-page-slider"> */}
        <SliderProduct
          Similar_product_name="New Arrival"
          products={
            homePageData?.data?.new_arival?.length > 0
              ? homePageData?.data?.new_arival
              : []
          }
        />
      {/* </div> */}

      {/* <div className="single-page-slider"> */}
        <SliderProduct
          Similar_product_name="Trending"
          products={
            homePageData?.data?.trending?.length > 0
              ? homePageData?.data?.trending
              : []
          }
        />
      {/* </div> */}
      {/* <SimilarProduct Similar_product_name="Top Rated"/> */}

      <SinglepageDetails />
    </div>
  );
}
