import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Order {
  getOrdersList = () => {
    return axios
      .get(`${API_URL}api/v1/sell/public/orders/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  orderDetails = (id) => {
    const url = `${API_URL}api/v1/sell/public/orders/${id}/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  cancelReasons = () => {
    const url = `${API_URL}api/v1/sell/public/cancel-order-reason/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  cancelOrder = (payload) => {
    const url = `${API_URL}api/v1/sell/public/cancel-request/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  deliveryCharge = (payload) => {
    const url = `${API_URL}api/v1/utility/public/shipping-rate/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getPaymentsList = () => {
    return axios
      .get(`${API_URL}api/v1/orders/payment/method/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  checkout = (formData, id) => {
    const url = `${API_URL}api/v1/sell/public/orders/`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${
          (
            JSON.parse(
              localStorage.getItem("tajira-website-logged-in-user-data")
            ) || {}
          ).token
        }`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  returnReasons = () => {
    const url = `${API_URL}api/v1/sell/public/return-reason/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  returnProduct = (payload = {}) => {
    const url = `${API_URL}api/v1/sell/public/return-refund/apply/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${
              (
                JSON.parse(
                  localStorage.getItem("tajira-website-logged-in-user-data")
                ) || {}
              ).token
            }`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
