import React from "react";
import { Space, Typography, Button } from "antd";
import emptyCartImg from "../../../images/emptycart.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";


import "./styles.scss";
import { LeftOutlined } from "@ant-design/icons";

const EmptyCart = () => {
  const history = useHistory();
  const { Title } = Typography;
  const location = useLocation();
  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  return (
    <section className="empty-cart">
      <Space direction="vertical" size={[20, 20]} align="center">
        <br />

        <img src={emptyCartImg} alt="" className="empty-cart-img" />
        <Title level={4}>Your cart is empty!</Title>


        <Button type="primary" onClick={() => history.push(`/${routepaths[1]}`)}>
        <LeftOutlined />
        Continue Shopping
      </Button>
       
      </Space>
    </section>
  );
};

export default EmptyCart;
