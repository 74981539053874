import React from "react";
import "./footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom.min";



export default function Footer() {
  return (
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo-details">
            <i className="fab fa-slack"></i>
            <span className="logo_name">ShopyfastBD</span>
          </div>
          <div className="media-icons">
            <a href="#">
              <FaFacebookF />
            </a>
            <a href="#">
              <FaInstagram />
            </a>
            <a href="#">
            <FaTwitter />
            </a>
            <a href="#">
            <FaYoutube />
            </a>
            <a href="#">
            <IoLogoTiktok />
            </a>
          </div>
        </div>
        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">Company</li>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">Contact us</a>
            </li>
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Get started</a>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Services</li>
           
            <li>
            <Link to="/wholesalerdashboard/sign-up"> <a href="#">Wholesaler</a></Link>
            </li>
            <li>
            <Link to="/resellerdashboard/sign-up"><a href="#">Reseller</a></Link>
            </li>
          </ul>
          
       
          <ul className="box input-box">
            <li className="link_name">Subscribe</li>
            <li>
              <input type="text" placeholder="Enter your email" />
            </li>
            <li>
              <input type="button" value="Subscribe" />
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-details">
        <div className="bottom_text">
          <span className="copyright_text">
            Copyright © 2024 <a href="#">ShopyfastBD.</a>All rights reserved
          </span>
          <span className="policy_terms">
            <a href="#">Privacy policy</a>
            <a href="#">Terms & condition</a>
          </span>
        </div>
      </div>
    </footer>
  );
}
