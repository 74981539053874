import React from "react";

export default function HowToBuy() {
  return (
    <div style={{marginLeft:"17px", fontSize:"14px"}}> 
* কালার এবং সাইজ সিলেক্ট করুন <br/>
* অ্যাড টু কার্ট অথবা বাই নাউ তে ক্লিক করুন<br/>
* আপনি যদি নিউ কাস্টমার হয়ে থাকেন সাইন আপ এ ক্লিক করে প্রয়োজনীয় ইনফরমেশন দিয়ে সাইন আপ করুন <br/>
* ডেলিভারি ঢাকার ভিতরে অথবা ঢাকার বাহিরে  সিলেক্ট করুন <br/>
* আপনি ফুল পেমেন্ট করবেন নাকি পার্শিয়াল পেমেন্ট করবেন সিলেক্ট করুন <br/>
* প্রয়োজনীয় ইনফরমেশন দিয়ে অর্ডার টি কমপ্লিট করুন <br/>
* অর্ডার কমপ্লিট হয়ে গেলে কিছুক্ষণ এর মধ্যে আপনার সাথে কন্টাক্ট করা হবে<br/>
    </div>
  );
}


{/* <ol>
<li> Select number of product you want to buy </li>
<li> Click Add to cart Button</li>
<li> Then go to checkout</li>
<li>
  If you are a new customer, please click on Sign up.Provide us your
  valid information.
</li>
<li>
  Complete your checkout, we recieved your order, and for order
  confirmation or customer service contact with you.
</li>
</ol> */}