/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Button, Space, Avatar, message } from "antd";
import {
  LikeOutlined,
  LikeFilled,
  MessageOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import moment from "moment";

import Spinner from "../../../common/spinner";
import { useSelector } from "react-redux";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";
import DataNotFound from "../no-data-for-blogs";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const { Paragraph, Title, Text } = Typography;

const NewsFeed = ({ handleShowComments }) => {
  const blog = new API.Pages();

  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const [loading, setLoading] = useState(false);
  const [newsFeed, setNewsFeed] = useState([]);
  const [comments, setComments] = useState({});

  useEffect(() => {
    fetchNewsFeedList();
  }, []);

  const fetchNewsFeedList = () => {
    getDataManager(blog?.getBlogs, setLoading, { type: 0 }).then((x) => {
      if (x?.status === "success") {
        setNewsFeed(x?.data?.results);
      } else {
        message.error({
          content: "Error ocurred while loading news feed",
        });
      }
    });
  };

  const addBlogComments = (payload) => {
    getDataManager(blog?.addBlogComments, null, payload).then((x) => {
      if (x?.status === "success") {
        message.success("comment added");
        fetchNewsFeedList();
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content:
            x?.message || error || "Error occurred while adding comments",
        });
      }
    });
  };

  const addRemoveLikes = (id) => {
    getDataManager(blog?.addRemoveLikes, null, id).then((x) => {
      if (x?.status === "success") {
        fetchNewsFeedList();
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content:
            x?.message || error || "Error occurred while liking the post",
        });
      }
    });
  };

  const onSubmit = (id) => {
    if (comments[id]) {
      const payload = {
        user: userData?.id,
        blog: id,
        comment: comments[id],
      };
      setComments({});
      addBlogComments(payload);
    } else {
      message.warning({ content: "Please add comment", duration: 3 });
    }
  };

  return (
    <>
      {(newsFeed || []).length === 0 && !loading && <DataNotFound />}
      <Row gutter={[20, 20]} justify="center" style={{ position: "relative" }}>
        {loading && <Spinner />}
        {(newsFeed || []).map((p) => {
          const {
            user_detail,
            image_details,
            description,
            total_likes,
            total_comments,
            creation_time,
          } = p;
          const { first_name, last_name, profile_image_url } = user_detail;
          return (
            <Col span={24} className="news-feed">
              <Card className="news-feed-card" bordered={false}>
                <Row gutter={[20, 20]}>
                  <Col span={24} className="text-and-more-icon">
                    <Space size={30}>
                      <Avatar src={profile_image_url} size={80} />
                      <div className="title-and-text">
                        <Title level={4}>
                          {first_name} {last_name}
                        </Title>
                        <Text>
                          {moment(creation_time).format("MMMM Do YYYY, h:mm A")}
                        </Text>
                      </div>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Paragraph className="news-feed-paragraph">
                      {description}
                    </Paragraph>
                  </Col>
                  <Col span={24}>
                    <OwlCarousel
                      className="owl-theme owl-loading owl-carousel"
                      margin={15}
                      autoplay={true}
                      autoplaySpeed={250}
                      autoplayTimeout={2000}
                      autoplayHoverPause={true}
                      lazyLoad={true}
                      items={1}
                    >
                      <img
                        src={image_details}
                        alt=""
                        width="100%"
                        height={500}
                        className="news-feed-img"
                      />
                    </OwlCarousel>
                  </Col>
                  <section className="add-comments-section">
                    <Space>
                      {total_likes > 0 ? (
                        <LikeFilled
                          className="liked"
                          onClick={() => addRemoveLikes(p?.id)}
                        />
                      ) : (
                        <LikeOutlined onClick={() => addRemoveLikes(p?.id)} />
                      )}
                      <span>({total_likes})</span>
                    </Space>
                    <Space>
                      <MessageOutlined
                        onClick={() => handleShowComments(p?.id)}
                      />
                      <span>({total_comments})</span>
                    </Space>

                    <div className="global-search-base-container w-100">
                      <Input
                        placeholder="Write Your Comments...."
                        onChange={(e) =>
                          setComments({ [p?.id]: e.target.value })
                        }
                        size="large"
                        className="w-100"
                        value={comments[p?.id]}
                      />
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      shape="circle"
                      icon={<SendOutlined />}
                      onClick={() => onSubmit(p?.id)}
                    />
                  </section>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default NewsFeed;
