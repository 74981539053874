import React, { useEffect, useState } from "react";
import {
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  LoginOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Badge, Dropdown, Popover, Space } from "antd";
import TopMenuBar from "./mini-components/top-menu-bar";
import GlobalSearch from "./mini-components/global-search";
import { RxAvatar } from "react-icons/rx";

import {
  setShowLoginModal,
  setIsLoggedIn,
  clearRedux,
  setRSLogo,
  setRSShopData,
  setRSCategory,
} from "../../utils/redux/actions/actions";

import emailForward from "../../images/email-forward.png";

import comanyLogo from "../../images/shopyfast-logo.png";

import "./styles.scss";
import { useHistory } from "react-router-dom";
import { API_URL, CSUserInfo, CSbearerToken } from "../../utils/constants";
import { useLocation, useParams } from "react-router-dom";
import { getDataManager } from "../../utils/helper.functions";
import API from "../../utils/api";
import Spinner from "../common/spinner";
import Reseler from "../../utils/api/Reseler";

const Header = (props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let shopname = window.location.href.split("/")[3];

  const Reseler = new API.Reseler();
  const getReselershopinfo = async () => {
    let shopdata = await getDataManager(
      Reseler?.getReselershopinfo,
      setLoading,
      {},
      shopname
    );
    if (shopdata?.status == true) {
      // dispatch(setRSSiteInfo());
    }

    return shopdata;
  };

  useEffect(async () => {
    let shopdata = await getReselershopinfo();
    dispatch(setRSLogo(shopdata?.data?.shop_data?.shop_logo));
    dispatch(setRSShopData(shopdata?.data?.shop_data));
  }, []);

  if (loading) {
    <Spinner />;
  }

  return (
    <header className="header-base-container">
      {/* <TopMenuBar /> */}
      <section className="header-main-contents">
        <Logo />
        <FreeQuote />
        <GlobalSearch compName="website"/>
        {/* <WishList /> */}
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Cart {...props} />
          <MyAccount />
        </div>
      </section>
    </header>
  );
};

export default Header;

const Logo = () => {
  const { logo } = useSelector((state) => {
    return state?.app;
  });
  const path = useHistory();
  let linktext = path.location.pathname.split("/")[1];
  let logotext = linktext.toUpperCase();
  return (
    <Link to={`/${linktext}`}>
      {/* <img className="company-logo" src={comanyLogo} alt="logo" /> */}
      {logo ? (
        <img className="company-logo" src={`${API_URL}/${logo}`} alt="logo" />
      ) : (
        <span
          style={{
            fontSize: "25px",
            fontWeight: "750",
            letterSpacing: ".5px",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          {logotext}
        </span>
      )}
    </Link>
  );
};

const FreeQuote = () => {
  return (
    <></>
    // <Link className="free-quote" to="/contact-us">
    //   <img src={emailForward} alt="" style={{color: 'white'}}/>
    //   <div className="free-quote--text">
    //     <span className="light-grey-text">For Any Query</span>

    //     <span className="blue-text">poshcart@gmail.com</span>
    //   </div>
    // </Link>
  );
};

const WishList = () => {
  const { wishlistItems } = useSelector((state) => state.app);

  let quantity = 0;

  (wishlistItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });

  return (
    <Link className="wishlist" to="/account/wishlist">
      <Badge count={quantity} showZero={true} style={{ color: "#397D86" }}>
        <HeartOutlined />
      </Badge>
      &nbsp;
      <span className="wishlist-text">Wishlist</span>
    </Link>
  );
};

const Cart = () => {
  let shopname = window.location.href.split("/")[3];

  const { cartItems } = useSelector((state) => state.app);
  const Reseler = new API.Reseler();

  const [cartCount, setcartCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchCartItems = () => {
    getDataManager(Reseler?.userGetCart, setLoading).then((x) => {
      if(x==undefined)
        {
         return;
        }
      if (x?.data?.is_empty_cart == true) {
        setcartCount(0);
      } else {
        setcartCount(1);
      }
    });
  };

  useEffect(() => {
    fetchCartItems();
  }, [cartItems]);

  return (
    <Link to={`/${shopname}/shopping-cart`}>
      <section className="cart">
        <Badge count={cartCount} showZero={true}>
          <ShoppingCartOutlined />
        </Badge>
        &nbsp;
        {/* <span className="cart-text">My Cart</span> */}
      </section>
    </Link>
  );
};

const MyAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userdata = CSUserInfo();
  let indexpage = window.location.href.split("/")[3];

  const handleLogout = () => {
    localStorage.removeItem("shopyfastbd-cs-token");
    dispatch(setIsLoggedIn(false));
    dispatch(clearRedux());
    history.push(`/${indexpage}`);
  };

  const handleViewAccount = () => {
    history.push("/account/my-account");
  };

  const handleViewMessages = () => {
    history.push("/all-messages");
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="logout-opt" onClick={handleViewAccount}>
          <UserOutlined />
          Account
        </div>
        <div className="logout-opt" onClick={handleViewMessages}>
          <MessageOutlined />
          Messages
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  const items = [
  
    {
      label: <div
      onClick={handleLogout}
    >
      Log Out
    </div>,
      key: '1',
    },
  ];

  return userdata?.role == "customer" ? (
    // <Popover
    //   placement="bottom"
    //   content={userPopover()}
    //   trigger="hover"
    //   className="user-popover"
    // >
    //   <section>
    //     {/* <div className="login-btn">Account</div> */}
       
    //   </section>
    // </Popover>
    <Dropdown
    menu={{
      items,
    }}
    trigger={['click']}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
      <Avatar size={30}>
          {userdata.email.substring(0, 2).toUpperCase()}
        </Avatar>
      </Space>
    </a>
  </Dropdown>
  ) : (
    // <div className="my-account">
    <UserOutlined
      onClick={() => dispatch(setShowLoginModal(true))}
      style={{ color: "white", fontSize: "27px" }}
    />
    //  <section>
    //   <div className="login-btn">Account</div>
    //   <div className="unsigned-options">
    //     <Link to="/register">
    //       <span>Register</span>
    //     </Link>{" "}
    //     or{" "}
    //     <span onClick={() => dispatch(setShowLoginModal(true))}>Sign in</span>
    //   </div>
    // </section>
    // </div>
  );
};
