import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { GiHamburgerMenu } from "react-icons/gi";

import "./shopifastlanding.scss";
import Footer from "./footer";
import LandingProducts from "./rsProduct/LandingProducts";
import landingbanner from "../../images/banner/landingbanner.jpg";
export default function Shopifastlanding() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    <div className="shopyfastlanding">
      <ShopyfastDrawer onClose={onClose} showDrawer={showDrawer} open={open} />
      <div className="nav">
        <div className="nav_menu nav-div">
          <span className="nav_menu_icon">
            <GiHamburgerMenu onClick={showDrawer} />
          </span>
          Shopyfastbd
        </div>
        <div className="section nav-div">
          {" "}
          <div>Wholesaler</div>
          <div>Reseler</div>
          <div>About Us</div>
          <div>Contact</div>
        </div>
      </div>
      <div className="banner_section">
        {/* <div className="banner_section_div banner_section_left">
          <div className="banner_section_left_text1 ">
            {" "}
            Wholesaler & Reseller hub
          </div>
          <div className="banner_section_left_text2">
            We provide the collaboration between wholesaler and reseller to
            expedite the e-commerce platform
          </div>
          <div className="banner_btn_div">
            <span className="banner_btn">Get Started</span>
            <span className="banner_btn">Learn More</span>
          </div>
        </div> */}
        <div className="banner_section_div banner_section_right">
          <img src={landingbanner} className="banner_section_right_image" />
        </div>
      </div>
      <LandingProducts />
      <div>
        <Footer />
      </div>
    </div>
  );
}

const ShopyfastDrawer = ({ showDrawer, onClose, open }) => {
  return (
    <>
      <Drawer
        title="ShopyfastBD"
        placement="left"
        onClose={onClose}
        open={open}
      >
        <p className="drawer-text">
          <span>Wholesaler</span>
        </p>
        <p className="drawer-text">
          <span>Reseller</span>
        </p>
        <p className="drawer-text">
          <span>About US</span>
        </p>
        <p className="drawer-text">
          <span>Contact US</span>
        </p>
      </Drawer>
    </>
  );
};
