import React from "react";
import { Radio, Space, Rate } from "antd";

const Rating = ({ selectedFilters, filtersSelection }) => {
  return (
    <Radio.Group
      onChange={(e) => filtersSelection("rating", e.target.value)}
      value={selectedFilters?.rating}
    >
      <Space size={15} direction="vertical">
        <Radio value="">All Rating</Radio>
        <Radio value={5}>
          <Rate value={5} disabled />
        </Radio>
        <Radio value={4}>
          <Rate value={4} disabled />
        </Radio>
        <Radio value={3}>
          <Rate value={3} disabled />
        </Radio>
        <Radio value={2}>
          <Rate value={2} disabled />
        </Radio>
        <Radio value={1}>
          <Rate value={1} disabled />
        </Radio>
      </Space>
    </Radio.Group>
  );
};

export default Rating;
