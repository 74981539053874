import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import "slick-carousel/slick/slick.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
/* Example path, adjust it based on the actual location */

import "slick-carousel/slick/slick-theme.css";
import { store } from "./utils/redux/store";
import Main from "./main.js";
import "./index.css";
import App from "./App.jsx";
import { PersistGate } from "redux-persist/integration/react";
import reduxPersist from "./utils/reduxPersist";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={reduxPersist.persistor}> */}
      <Router>
        <App />
      </Router>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
