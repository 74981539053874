import React from "react";

import { Breadcrumb } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./styles.scss";

const ArabsToolsBreadcrumb = ({ paths = [] }) => {
  let pathList = [];
  const history = useHistory("");
  const current = paths[paths.length - 1];
  const isAccount = paths.includes("Account");
  pathList = isAccount ? paths.filter((p) => p !== "Account") : paths;
  return (
    <Breadcrumb className="tajira-bread-crumbs-container">
      {!isAccount ? (
        <Breadcrumb.Item>
          <div className="previous-route" onClick={() => history.push("/")}>
            <HomeFilled style={{ marginRight: "10px" }} /> Home
          </div>
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item>
          <div
            className="previous-route"
            onClick={() => history.push("/account")}
          >
            <HomeFilled style={{ marginRight: "10px" }} /> Account
          </div>
        </Breadcrumb.Item>
      )}
      {pathList.map((p, i) => (
        <Breadcrumb.Item key={i}>
          <div className={p === current ? "current-route" : "previous-route"}>
            {p}
          </div>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default ArabsToolsBreadcrumb;
