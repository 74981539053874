import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Address {
  getaddressList = () => {
    return axios
      .get(`${API_URL}api/v1/utility/public/address/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  getaddressDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/utility/public/address/${id}/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  addAddress = (payload) => {
    const url = `${API_URL}api/v1/utility/public/address/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  updateAddress = (payload) => {
    const url = `${API_URL}api/v1/utility/public/address/${payload?.id}/`;
    return axios
      .put(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  deleteAddress = (id) => {
    const url = `${API_URL}api/v1/utility/public/address/${id}/`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };

  getStateList = (id) => {
    let url = `${API_URL}api/v1/auth/state`;
    if (id) {
      url = url + `/?country=${id}`;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error?.response?.data?.message;
      });
  };
}
