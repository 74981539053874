import React, { Component } from "react";
import Slider from "react-slick";
import "./imageSlider.scss";
import { API_URL } from "../../../utils/constants";
export default function ImageSlider({
  imagesArr,
  setmainimage,
  imageSelectedId,
  setimageSelectedId,
}) {
  let arr = [1, 1, 1, 1];
  let arrlength = imagesArr.length
  var settings = {
    className: "single-product-slider",
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: `${arrlength<3? 2 : (arrlength<4?3:(arrlength<5?4:5))}`,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: `${arrlength<3? 2 : (arrlength<4?3:(arrlength<5?4:5))}`,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: `${arrlength<3? 2 : (arrlength<4?3:(arrlength<5?4:5))}`,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const mainImageChange = (id) => {
    let mainimage = imagesArr.find((item, index) => item._id == id);
    setmainimage(mainimage.image);
    setimageSelectedId(id);
  };
 
  if (imagesArr?.length < 0) {
    return <p>""</p>;
  } else {
    return (
      <div className="singleProduct-slider-div">
        <Slider {...settings}>
          {imagesArr?.map((item, index) => {
            return (
              <div key={index}>
                <p
                  className="thumbnails-img"
                  onClick={() => mainImageChange(item._id)}
                  style={{
                    border: `${
                      imageSelectedId == item._id
                        ? "3px solid #397D86"
                        : "2px solid grey"
                    }`,
                  }}
                >
                  <img
                    src={`${API_URL}/${item.image}`}
                    className="thumbnails-img-src"
                  />

{/* <img
                    src="https://api.shopyfastbd.com/uploads/images/2024-01-28T10-26-28.428Z-prod-6146356.T900%20.jpg"
                    className="thumbnails-img-src"
                  /> */}


                </p>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

ImageSlider.defaultProps = {
  imagesArr: [],
};
