import axios from "axios";
import { API_URL, TOKEN, Tokenfunc } from "../constants";

export default class Upload {
  uploadImage = (payload) => {
    const url = `${API_URL}/api/wholesaler/product/images`;
    const bearerToken = Tokenfunc();

    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
        
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  uploadWholeSaleProduct = (payload) => {
    const url = `${API_URL}/api/wholesaler/product`;
    const bearerToken = Tokenfunc();

    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  editWholeSaleProduct = (payload, id) => {
    const url = `${API_URL}/api/wholesaler/product/${id}`;
    const bearerToken = Tokenfunc();
    return axios
      .put(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
        
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  getCategory = () => {
    const url = `${API_URL}/api/admin/category`;
    const bearerToken = Tokenfunc();

    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };
}
