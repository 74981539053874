/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import "./styles.scss";

const SideBar = ({ children }) => {
  return <div className="tajira-sidebar">{children}</div>;
};

export default SideBar;
