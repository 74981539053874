import React from "react";
import { Typography, Modal, Button } from "antd";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubTotal } from "../../../../utils/helper.functions";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const AddToCart = ({ product = {}, showModal, closeModal }) => {
  const history = useHistory();

  const { name, new_price, image } = product;

  const { cartItems } = useSelector((state) => state.app);

  let quantity = 0;
  let cartProducts = [];

  (cartItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
    cartProducts = [...cartProducts, ...w?.products];
  });

  const renderProductDetail = () => {
    return (
      <div className="product-added-in-cart-product-detail">
        <Title level={4}>{name}</Title>
        <div className="product-detail-price-info">
          <Title level={5} className="price">
            BDT {new_price}
          </Title>
        </div>
      </div>
    );
  };

  const renderCartSection = () => {
    return (
      <div className="product-added-in-cart-right-section">
        <div className="right-section-details">
          <Title level={4}>{`There are ${quantity} items in your cart`} </Title>
          <div className="cart-section-details">
            <Paragraph style={{ color: "#484848", fontWeight: "500" }}>
              Order subtotal:
            </Paragraph>
            <Paragraph style={{ color: "rgba(0,0,0,0.87)" }}>
              BDT {getSubTotal(cartProducts)}
            </Paragraph>
          </div>
        </div>
        <div className="cart-section-footer">
          <Button type="default" shape="round" onClick={closeModal}>
            Continue Shopping
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => history.push("/shopping-cart")}
          >
            Proceed To Checkout
          </Button>
        </div>
      </div>
    );
  };

  const renderModalTitle = () => {
    return (
      <div className="tajira-product-added-in-cart-title">
        <CheckCircleFilled />
        <Title level={5}>
          Product Successfully added to your Shopping cart
        </Title>
      </div>
    );
  };

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      title={renderModalTitle()}
      wrapClassName="tajira-product-added-in-cart-container"
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
      footer={null}
      width="1000px"
    >
      <div className="tajira-product-added-in-cart">
        <img src={image} alt={""} width="150px" height="150px" />
        {renderProductDetail()}
        {renderCartSection()}
      </div>
    </Modal>
  );
};

export default AddToCart;
