import React from "react";
import "./rslrCArd.scss";
import { GrStar } from "react-icons/gr";
import { API_URL } from "../../../utils/constants";
import { TbCurrencyTaka } from "react-icons/tb";
import { useWindowSize } from "../../../utils/custom-hooks";
import { Button, Popover } from "antd";
import { Link } from 'react-router-dom';

export default function ResellerCard({ item, routename }) {
  const percentage = Math.floor(Math.random() * (40 - 20 + 1)) + 20;
  // const percentage = item?.price < 200 ? 20 : (item?.price < 300 ? 25 : (item?.price < 400 ? 30 :(item?.price < 500 ? 35 :(item?.price < 600 ? 38 : (item?.price < 700 ? 32 :28)) )))
  const calculatedPercentage = (percentage / 100) * item.price;
  const lessprice = Math.floor(calculatedPercentage);


  var numbers = [3.9, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5];
  var randomIndex = Math.floor(Math.random() * numbers.length);
  var rating = numbers[randomIndex];


  // var rating = item?.price < 200 ? 4.2 : (item?.price < 300 ? 4.3 : (item?.price < 400 ? 4.4 :(item?.price < 500 ? 4.5 :(item?.price < 600 ? 4.6 : (item?.price < 700 ? 4.7 :4.8)) )))



  const windowsize = useWindowSize();
  const navigation = () => {
  }

  const content = (
    <div>
      {/* <div style={{ fontWeight:550, padding:"3px", borderRadius:"2px", background:"grey", marginBottom:"5px",color:"white"}}></div> */}
      {/* <div style={{ fontWeight:550, padding:"3px", borderRadius:"2px", background:"grey", color:"white"}}></div> */}
   <Button size="small" style={{marginBottom:"8px"}}><Link to="/wholesalerdashboard/sign-up">Join as wholesaler</Link></Button> <br/>
   <Button size="small"><Link to="/resellerdashboard/sign-up">Join as reseller</Link></Button>

   
    </div>
  );

  return (
    <Link className="cards" to={`/${routename}/product/${item?._id}`}>
      {/* <div className="discount">-{percentage}%</div> */}
      <img
        src={
          API_URL +
          "/" +
          item.images.find((item) => item.mainImage == true).image
        }
        alt=""
        height="200px"
      />
      <div className="card-details">
        <div className="product-sold-rating">
          {" "}
          <span className="product-sold">{percentage - 10} sold </span>
          <span className="product-rating">
            | <GrStar style={{ translate: "0px 2px", color: "grey" }} /> {rating}
          </span>
        </div>
        <div className="product-name">
          {item?.title?.substring(0, `${windowsize?.width < 350 ? 38 : (windowsize?.width < 480 ? 34 : 42)}`)}
        </div>

        <Popover content={content}>
          <div className="land-btn-without-price">
            Register to see the price
          </div>  </Popover>
      </div>
      {/* <div className="buy-btn">Buy Now</div> */}
    </Link>
  );
}
