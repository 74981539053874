import React, { useEffect, useState } from "react";
import ResellerCard from "../similarproduct/rslrCard";
import "./rsProduct.scss";
import SideBar from "../../common/sidebar";
import { Button, Card, Collapse, Radio, Space, notification } from "antd";
import {
  AppstoreFilled,
  CaretRightOutlined,
  MailOutlined,
  MenuOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import Categories from "../../home/search-results/categories";
import Brands from "../../home/search-results/brands";
import PriceSlider from "../../common/price-slider";
import Rating from "../../common/rating";
import FilterBody from "../../common/filter-detail";
import { Pagination } from "antd";
import { getDataManager } from "../../../utils/helper.functions";
import API from "../../../utils/api";
import Spinner from "../../common/spinner";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setRSCategory,
  setRSLogo,
  setRSShopData,
} from "../../../utils/redux/actions/actions";
import { useHistory } from "react-router-dom";
import CustomNotification from "../../common/custom-notification/customNotification";

const { Panel } = Collapse;
let routename = "";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
export default function RsProducts() {
  var intervalId
  const dispatch = useDispatch();
  const [siteInfo, setSiteInfo] = useState("");
  const [toggle, setToggle] = useState(false);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const Reseler = new API.Reseler();
  const [pagination, setpagination] = useState(10);
  const [categoryList, setCategoryList] = useState([]);
  const [showFilterDetail, setShowFilterDetail] = useState(false);
  const [CustomNotificationdata, setCustomNotificationdata] = useState({
    toggle: false,
    title: "",
    body: "",
    imageUrl: "",
  });
  const location = useLocation();
  const routePath = location.pathname;
  const routepaths = routePath.split("/");

  const [selectedFilters, setSelectedFilters] = useState({
    max_price: 10000,
    min_price: 1,
  });

  const [params, setParams] = useState({
    pageno: "1",
    size: "20",
    category: "",
    owner: "",
    max_price: 10000,
    min_price: 1,
    sort_by: "price_asc",
  });

  const queryParams = new URLSearchParams(location.search);
  const paramCategory = queryParams.get("category");

  const history = useHistory();

  const handlePagination = (e) => {
     setParams({...params, pageno:e });
  };

  const debounce = (func, delay) => {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleChageSelectedFilters = debounce((name, value) => {
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      [name]: value,
    }));
  }, 10);

  const handleChangeParams = debounce((name, value) => {
    const regex = /^\s*$|^\d+$/;
    if (value > 0 && value < 10001 && regex.test(value))
      setParams((params) => ({ ...params, [name]: value }));
    setSelectedFilters((selectedFilters) => ({
      ...selectedFilters,
      [name]: value,
    }));
  }, 500);

  const handleChangeCategory = (name, value) => {
    // setParams({ ...params, [name]: value });
    let cat = value.replace("&", "-");
    history.push(`/${routename}/product?category=${cat}`);
  };

  const handleChangeSorby = (e) => {
    setParams({
      ...params,
      sort_by: `${e.target.value == "price_asc" ? "price_asc" : "price_dsc"}`,
    });
  };

  const getReselershopinfo = async (shopname) => {
    let shopdata = await getDataManager(
      Reseler?.getReselershopinfo,
      setLoading,
      {},
      shopname
    );

    if (shopdata?.status == true) {
      setSiteInfo(shopdata);
      let categori = [
        { label: "All Categories", value: "" },
        ...shopdata?.data?.category,
      ];
      let items = categori?.map((item, index) => {
        return getItem(
          <span
            onClick={() => {
              handleChangeCategory("category", item.value);
            }}
          >
            {" "}
            {item.label}
          </span>,
          item.value,
          <MailOutlined />,
          item.children &&
            item.children.map((item, index) => {
              return getItem(
                <span
                  onClick={() => {
                    handleChangeCategory("category", item.value);
                  }}
                >
                  {" "}
                  {item.label}
                </span>,
                item.value,
                null,
                item.children &&
                  item.children.map((item, index) => {
                    return getItem(
                      <span
                        onClick={() => {
                          handleChangeCategory("category", item.value);
                        }}
                      >
                        {" "}
                        {item.label}
                      </span>,
                      item.value
                    );
                  })
              );
            })
        );
      });

      // items = [getItem("Categories", "Categories", <MailOutlined />, items)];
      setCategoryList(items);
    }

    return shopdata;
  };

  const getReselerAllProduct = async (params, id) => {
    let productdata = await getDataManager(
      Reseler?.getReselerAllProduct,
      setLoading,
      {},
      id,
      params
    );
    if (productdata?.status == true) {
      setProductData(productdata?.data?.data);
      randomnotificationshow();

      let pagenumber = productdata?.data?.totalDocuments / 20;
      pagenumber = Math.floor(pagenumber + 1);
      setpagination(productdata?.data?.totalPages * 10);
    }
  };

  const randomnotificationshow = () => {
    intervalId = setInterval(() => {
      RandomNotification();
    }, 15000);
  };

  var tempbool = false;

  const RandomNotification = () => {
    let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
    let randomn = Math.floor(Math.random() * (10 - 0)) + 0;
    let names = ["Mamun", "Rafi", "Rubel", "Rasel", "Rahul", "Rimi", "Ritu", "Nili", "Ruman", "Mahi", "Mitu"];
    let togg = [false, true, false, true, false];
    const product = productData[randomp];
    const randomtogg = togg[randomn];
     

    const randomname = names[randomn];

    if(tempbool === randomtogg)
    {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle:  !randomtogg,
        name: randomname
      });
      tempbool = !randomtogg
    }
    else
    {
      setCustomNotificationdata({
        ...CustomNotificationdata,
        toggle:randomtogg,
        name: randomname
      });
      tempbool = randomtogg

    }
  };

  const { category, pageno } = params;

  useEffect(() => {
    if (siteInfo) {
      getReselerAllProduct(params, siteInfo?.data?.shop_data?._id);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [category, siteInfo, pageno, toggle]);

  useEffect(() => {
    if (siteInfo) {
      routename = siteInfo?.data?.shop_data?.shop_url;
      dispatch(setRSCategory(siteInfo?.data?.category));
      dispatch(setRSLogo(siteInfo?.data?.shop_data?.shop_logo));
      dispatch(setRSShopData(siteInfo?.data?.shop_data));
    }
  }, [siteInfo]);

  useEffect(() => {
    if (paramCategory != null) {
      setParams({ ...params, category: paramCategory });
    }

    if (routepaths[2] == "product") {
      getReselershopinfo(routepaths[1]);
    }
  }, [paramCategory]);

  if (loading) {
    return <Spinner />;
  }

  let randomp = Math.floor(Math.random() * (productData.length - 1)) + 1;
  const notiproduct = productData[randomp];

  return (
    <>
      <div className="rs-all-products">
      {/* CustomNotificationdata?.toggle  */}
        {CustomNotificationdata?.toggle && (
            <CustomNotification product = {notiproduct} name = {CustomNotificationdata?.name}/>
          
        )}
        <div className="category-section">
          <SideBar>
            <Card className="card-shadow filters-sidebar">
              <Collapse
                defaultActiveKey={["1"]}
                ghost
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel
                  header="Select Category"
                  key="1"
                  style={{ maxWidth: "250px", paddingBottom: "0px" }}
                >
                  <Categories
                    categories={categoryList}
                    // filtersSelection={filtersSelection}
                    // selectedFilters={selectedFilters}
                  />
                </Panel>
                {/* <Panel header="Select Brand" key="2">
                  <Brands
                  // brands={brands}
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                  />
                </Panel> */}
                <Panel header="Price" key="2">
                  <PriceSlider
                    // filtersSelection={filtersSelection}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    handleChangeParams={handleChangeParams}
                    handleChageSelectedFilters={handleChageSelectedFilters}
                  />
                </Panel>
                <Panel header="SortBy" key="3">
                  <Radio.Group
                    onChange={handleChangeSorby}
                    value={params?.sort_by}
                  >
                    <Space direction="vertical">
                      <Radio value={"price_asc"}>Price Low to high</Radio>
                      <Radio value={"price_dsc"}>Price high to low</Radio>
                    </Space>
                  </Radio.Group>
                </Panel>

                <Panel header="Rating" key="4">
                  <Rating
                  // filtersSelection={filtersSelection}
                  // selectedFilters={selectedFilters}
                  />
                </Panel>
              </Collapse>
              <br />
              <Space
                direction="vertical"
                className="w-100"
                style={{ padding: "0 20px 20px 20px" }}
                size={15}
              >
                <Button
                  type="primary"
                  block
                  shape="round"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  Apply
                </Button>
                <Button
                  type="default"
                  block
                  shape="round"
                  onClick={() => {
                    setParams({
                      pageno: "1",
                      size: "20",
                      category: "",
                      owner: "",
                      max_price: "",
                      min_price: "",
                      sort_by: "",
                    });
                  }}
                >
                  Reset Filter
                </Button>
              </Space>
            </Card>
          </SideBar>
        </div>
        <div className="rslr-whole-product">
          <div className="view-mode-container">
            {/* <Space>
              <AppstoreFilled
              // onClick={() => setViewMode("grid")}
              // className={viewMode === "grid" ? "selected-mode" : "mode"}
              />
              <MenuOutlined
              // onClick={() => setViewMode("list")}
              // className={viewMode === "list" ? "selected-mode" : "mode"}
              />
            </Space> */
            !showFilterDetail && (
              <div className="filters-toggler" style={{ marginLeft: "8px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowFilterDetail(!showFilterDetail);
                  }}
                  style={{backgroundColor:"#397D86", color:"white"}}
                >
                  <SlidersOutlined /> Filter
                </Button>
              </div>
            )}
          </div>
          {// showFilterDetail

          showFilterDetail && (
            <div className="filters-toggler">
              <FilterBody
                categories={categoryList}
                showFilterDetail={showFilterDetail}
                setShowFilterDetail={setShowFilterDetail}
                // brands={brands}
                // filtersSelection={filtersSelection}
                selectedFilters={selectedFilters}
                // handleApplyFilter={handleApplyFilter}
                // handleClearFilter={handleClearFilter}
                setSelectedFilters={setSelectedFilters}
                handleChangeParams={handleChangeParams}
                toggle={toggle}
                setToggle={setToggle}
                params={params}
                setParams={setParams}
                handleChangeSorby={handleChangeSorby}
              />
            </div>
          )}
          <div className="rslr-product">
            {productData &&
              productData.length > 0 &&
              productData.map((item, index) => {
                return (
                  <div className="rslr-single-card">
                    <ResellerCard
                      item={item}
                      key={index}
                      routename={routename}
                    />
                  </div>
                );
              })}
          </div>
          <Pagination
            className="pagination"
            defaultCurrent={10}
            total={pagination}
            style={{ margin: "auto" }}
            onChange={handlePagination}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
}
