/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Typography, Space } from "antd";

import ArabsToolsBreadcrumb from "../../common/breadcrumb";
import { useSelector } from "react-redux";

import liveChatIcon from "../../../images/live-chat.png";
import whatsappIcon from "../../../images/whatsapp.png";
import messengerIcon from "../../../images/messenger.png";
import customerIcon from "../../../images/customer-care.png";
import addressIcon from "../../../images/office-address.png";
import emailIcon from "../../../images/blue-email.png";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const ContactUs = () => {
  const { infoPage } = useSelector((state) => {
    return state?.app;
  });
  return (
    <section className="tajira-contact-us">
      <ArabsToolsBreadcrumb paths={["Contact Us"]} />
      <div className="main-heading" level={4}>
        Contact Us
      </div>
      <br />
      <Card bordered={false} className="contact-us-container-card">
        <Title level={2}>Get In Touch</Title>
        <Paragraph>We are always reachable on Call 24 Hours</Paragraph>
        <br />
        <section className="cards-section">
          <Card className="contact-us-info-card" bordered={false}>
            <Space size={20}>
              <div className="icons-container">
                <img src={liveChatIcon} width={30} alt="" />
                <a href={`chat:live chat`}>
                  <Paragraph>Live Chat</Paragraph>
                </a>
              </div>
              <a
                href={`https://wa.me/${infoPage?.whatsapp}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="icons-container">
                  <img src={whatsappIcon} width={30} alt="" />
                  <Paragraph>Whatsapp</Paragraph>
                </div>
              </a>
              <a
                href={`http://m.me/${infoPage?.messenger_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="icons-container">
                  <img src={messengerIcon} width={30} alt="" />
                  <Paragraph>Messenger</Paragraph>
                </div>
              </a>
            </Space>
            <Title level={5}>Chat With Us</Title>
          </Card>
          <Card className="contact-us-info-card" bordered={false}>
            <img src={customerIcon} width={40} alt="" />
            <a href={`tel:${infoPage?.phone}`}>
              <Title level={5}>{infoPage?.phone}</Title>
              <Paragraph>Contact Number</Paragraph>
            </a>
          </Card>
          <Card className="contact-us-info-card" bordered={false}>
            <img src={addressIcon} width={40} alt="" />

            <Title level={5}>{infoPage?.address}</Title>
            <Paragraph>Office Address</Paragraph>
          </Card>
          <Card className="contact-us-info-card" bordered={false}>
            <img src={emailIcon} width={40} alt="" />
            <a href={`mailto:${infoPage?.email}`}>
              <Title level={5}> {infoPage?.email}</Title>
              <Paragraph>Email Address</Paragraph>
            </a>
          </Card>
        </section>
        {/* <Paragraph>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum
        </Paragraph> */}
      </Card>
    </section>
  );
};

export default ContactUs;
