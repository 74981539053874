import axios from "axios";
import { API_URL, TOKEN, RSTokenfunc, CSTokenfunc } from "../constants";

export default class Reseler {
  postReselrSingleProduct = (payload) => {
    const url = `${API_URL}/api/reseller/product`;
    const bearerToken = RSTokenfunc();
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  editReselrSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/reseller/product/${id}`;
    const bearerToken = RSTokenfunc();
    return axios
      .put(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        return error;
      });
  };

  getReselerProduct = (payload, id, params) => {
    const url = `${API_URL}/api/reseller/product/own?page=${params?.pageNo}&size=200`;
    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getReselerSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/reseller/product/own/${id}`;
    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getReselerAllDashboardProduct = (payload,params) => {
    console.log("params", params);
    const url = `${API_URL}/api/reseller/product/all?page=${params?.pageNo}&search=${params?.searchtext || ""}&size=20`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getRSDBSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/reseller/product/all/${id}`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  addtowishlist = (payload) => {
    const url = `${API_URL}/api/reseller/wishlist/add`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  deletefromwishlist = (payload) => {
    const url = `${API_URL}/api/reseller/wishlist/delete`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getdatafromwishlist = ({}, id, params) => {
    const url = `${API_URL}/api/reseller/wishlist/list?page=${params?.pageNo}&size=20`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getRSHomeData = () => {
    const url = `${API_URL}/api/reseller/shop/balance`;
    const bearerToken = RSTokenfunc();
    
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getRSWithdrawtable = () => {
    const url = `${API_URL}/api/common/withdraw/request?page=1&limit=100`;
    const bearerToken = RSTokenfunc();
    
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getResellerOrder = () => {
    const url = `${API_URL}/api/reseller/order`;

    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  // user website apis

  getReselershopinfo = (payload, id) => {
    const url = `${API_URL}/api/reseller/shop/info/${id}`;

    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getReselerHomePageProduct = (payload, id) => {
    const url = `${API_URL}/api/reseller/shop/home/${id}`;

    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getReselerAllProduct = (payload, id, params) => {
    const url = `${API_URL}/api/reseller/shop/product/${id}?page=${params.pageno}&limit=20&category=${params.category}&min_price=${params.min_price}&max_price=${params.max_price}&sort_by=${params.sort_by}`;
    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  deletereselerProduct = (payload, deleteid) => {
    const url = `${API_URL}/api/reseller/product/${deleteid}`;

    console.log({url});
    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .delete(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getReselersingleProduct = (payload, id) => {
    const url = `${API_URL}/api/reseller/shop/singe_product/${id}`;

    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  userAddToCart = (payload) => {
    const url = `${API_URL}/api/customer/cart`;

    const bearerToken = CSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  userGetCart = () => {
    const url = `${API_URL}/api/customer/cart`;

    const bearerToken = CSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  userDeleteCart = () => {
    const url = `${API_URL}/api/customer/cart`;
    const bearerToken = CSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .delete(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  uploadReselerBanner = (payload) => {
    const url = `${API_URL}/api/reseller/banner`;
    const bearerToken = RSTokenfunc();
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  uploadReselerLogo = (payload) => {
    const url = `${API_URL}/api/reseller/banner/logo`;
    const bearerToken = RSTokenfunc();
    return axios
      .post(
        url,
        {
          shop_logo: payload,
        },
        {
          headers: {
            Authorization: `${bearerToken}`,
          },
        }
      )
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  checkoutproduct = (payload) => {

    
    const url = `${API_URL}/api/customer/checkout`;

    const bearerToken = CSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .post(url, payload, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  checkpaymentstatus = (payload, id) => {

    const url = `${API_URL}/api/common/payment/verify?invoice_id=${id}`;

    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  // search apis

  getReselerAllSearchResults = (payload, id, value) => {
    const url = `${API_URL}/api/reseller/shop/product/${id}?search=${value}`;
    return axios
      .get(url)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };




  getProfileInfo = () => {
    const url = `${API_URL}/api/common/profile`;
    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  updateProfileInfo = (payload) =>{
    const url = `${API_URL}/api/common/profile`;
    const bearerToken = RSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .put(url,payload,{
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  }
}
