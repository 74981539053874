import React, { useState } from "react";
import { Typography, Button, Form, Input, message } from "antd";
import OtpInput from "react-otp-input";

import BaseModal from "../../../common/base-modal";
import Spin from "../../../common/spinner";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";

import mobileLockIcon from "./../../../../images/mobile-lock-password.png";

import "./styles.scss";

const { Title } = Typography;

const CodeVerificationModal = ({ showModal, closeModal }) => {
  const auth = new API.Auth();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();

  const handleOtpChange = (o) => {
    setCode(o);
  };

  const onFinish = (values) => {
    const payload = {
      username: values?.username,
      otp_code: code,
      password: values?.password,
    };

    getDataManager(auth?.forgetPassword, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        closeModal();
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content:
            x?.message || error || "Error ocurred while changing password",
          duration: 3,
        });
      }
    });
  };

  return (
    <BaseModal showModal={showModal} closeModal={closeModal}>
      {loading && <Spin title="Verifying code..." />}
      <div className="tajira-email-verification">
        <img src={mobileLockIcon} alt="" height={80} />
        <br />
        <Title level={4}>Reset your password</Title>
        <Title level={5} className="code-sent-description">
          A code has been sent to your mobile, please check and enter here
        </Title>
        <div className="verification-inputs-render">
          <OtpInput
            value={code}
            onChange={handleOtpChange}
            numInputs={6}
            containerStyle="verification-code-container"
            inputStyle="one-digit-input"
          />
          <br />
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter password" size="large" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter password again" size="large" />
            </Form.Item>
            {/* <Paragraph style={{ marginTop: "10px" }}>
              Don't get yet?{" "}
              <span
                style={{ color: "#ff9601", cursor: "pointer" }}
                onClick={resendVerificationCode}
              >
                Send again
              </span>
            </Paragraph> */}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                disabled={code?.length < 6}
              >
                Set Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </BaseModal>
  );
};

export default CodeVerificationModal;
