import axios from "axios";
import { API_URL, TOKEN,  WSTokenfunc } from "../constants";

export default class Wholesale {
  getWholesaleProduct = (payload, id, params) => {
    const url = `${API_URL}/api/wholesaler/product?page=${params.pageNo}&size=20&owner=true`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getWholesaleAllProduct = (payload, id, params) => {
    const url = `${API_URL}/api/wholesaler/product?page=${params.pageNo}&search=${params.searchtext}&size=20`;
    const bearerToken = WSTokenfunc();
    if (bearerToken.length < 20) {
      return;
    }
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/wholesaler/product/${id}`;
    const bearerToken = WSTokenfunc();
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };

  getWSSingleProduct = (payload, id) => {
    const url = `${API_URL}/api/wholesaler/product/${id}`;
    const bearerToken = WSTokenfunc();
    return axios
      .get(url, {
        headers: {
          Authorization: `${bearerToken}`,
        },
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function(error) {
        return error?.response?.data;
      });
  };
}
