import React from "react";
import { Typography, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { sidebarMenuItems } from "../blogs-sidebar/helpers";

import "./styles.scss";

const { Paragraph } = Typography;

const AccountSidebar = ({
  selectedMenu = "My Dashboard",
  setSelectedMenu,
  showSidebar,
  onCloseSidebar,
}) => {
  const renderMenuItem = (item) => {
    const { icon, activeIcon, menuItemLabel } = item;
    const isActive = selectedMenu === menuItemLabel;
    return menuItemLabel === "Returns" ? (
      <a
        href="https://arabtools.com/page/return"
        className={`account-menu-item ${
          selectedMenu === menuItemLabel && "active"
        }`}
      >
        <img src={icon} alt={menuItemLabel} />
        <Paragraph>{menuItemLabel}</Paragraph>{" "}
      </a>
    ) : (
      <div
        className={`account-menu-item ${
          selectedMenu === menuItemLabel && "active"
        }`}
        onClick={() => {
          setSelectedMenu(menuItemLabel);
        }}
      >
        <img
          src={isActive ? activeIcon : icon}
          alt={menuItemLabel}
          width={18}
        />
        <Paragraph>{menuItemLabel}</Paragraph>
      </div>
    );
  };

  return (
    <Drawer
      placement="left"
      title={<DrawerTitle onCloseSidebar={onCloseSidebar} />}
      closable={false}
      onClose={onCloseSidebar}
      visible={showSidebar}
      width={"80%"}
    >
      {(sidebarMenuItems || [])
        .filter((opt) => opt?.icon)
        .map((item) => renderMenuItem(item))}
    </Drawer>
  );
};

export default AccountSidebar;

const DrawerTitle = ({ onCloseSidebar }) => {
  return (
    <div className="menu-sidebar-title" style={{ justifyContent: "flex-end" }}>
      <CloseOutlined className="drawer-close-icon" onClick={onCloseSidebar} />
    </div>
  );
};
