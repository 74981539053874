import { Space } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import { API_URL } from "../../../../../utils/constants";
import { useSelector } from "react-redux";

const SearchResults = ({
  hideSearchResults,
  clearSearch,
  products,
  compname,
}) => {
  const { rsshopdata } = useSelector((state) => {
    return state?.app;
  });

  let slicedProducts = products;
  slicedProducts.splice(10);

  const ele = document.getElementById("tajira-search-results");
  window.addEventListener("click", function(e) {
    if (ele && !ele.contains(e.target)) {
      hideSearchResults();
      window.removeEventListener("click", null);
    }
  });

  console.log("product data =============== ", products, compname);

  return (
    <div id="tajira-search-results" className="tajira-search-results">
      <Space direction="vertical" size={10} align="start">
        {(slicedProducts || []).map((product, index) => {
          return (
            <Link
              key={index}
              className="product-row"
              to={
                compname == "website"
                  ? `/${rsshopdata?.shop_url}/product/${product?._id}` : 
                  (compname == "reseller"?`/resellerdashboard/single-product?productId=${product?._id}&productstatus="reselersingleproduct"`
                  : `/wholesalerdashboard/single-product?productId=${product?._id}`)
              }
              onClick={() => {
                clearSearch();
                hideSearchResults();
              }}
            >
              <img
                src={`${API_URL}/${
                  product.images.find((item) => item.mainImage == true).image
                }`}
                alt=""
                className="prod-image"
              />
              <section className="prod-description">
                {compname == "website" && (
                  <>
                    <div className="title">{product?.product?.title}</div>
                    <div className="price">{Number(product?.price)} TK</div>
                  </>
                )}

                {compname == "reseller" && (
                  <>
                    <div className="title">{product?.title}</div>
                    <div className="price">
                      {Number(product?.reseller_price?.price)} TK
                    </div>
                  </>
                )}

                {compname == "wholesaler" && (
                  <>
                    <div className="title">{product?.title}</div>
                    <div className="price">{Number(product?.price)} TK</div>
                  </>
                )}
              </section>
            </Link>
          );
        })}
      </Space>
      {/* <div className="search-results-see-more">See All Result (12)</div> */}
    </div>
  );
};

export default SearchResults;
