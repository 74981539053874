import axios from 'axios';
import { API_URL } from '../constants';

export default class Tracking {
  checkTrackingStatus = (id) => {
    return axios
      .get(`${API_URL}api/v1/orders/delivery/tracking/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
