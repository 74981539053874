/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import { message } from "antd";
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowRegisterModal,
  setShowLoginModal,
} from "./utils/redux/actions/actions";
import API from "./utils/api";
import { checkIfSecureRoute } from "./utils/helper.functions";
import "./App.scss";
import Shopifastlanding from "./components/shopifastlanding/shopifastlanding";
import RsProducts from "./components/reseller/rsProduct/rsProducts";
import RouteHandler from "./components/common/routehandler/routeHandler";
import RsSingleProduct from "./components/reseller/rsSingleproduct/rsSingleProduct";
import Header from "./components/header";
import MenuSideBar from "./components/mobile-view-components/menu-sidebar";
import MobileHeader from "./components/mobile-view-components/header";
import Footer from "./components/footer";
import Login from "./components/home/modals/login";
import Register from "./components/home/modals/register";
import Spinner from "./components/common/spinner";
import Checkout from "./components/home/checkout";
import CancelPayment from "./components/common/SuccessPayment/CancelPayment";
import Successpayment from "./components/common/SuccessPayment/Successpayment";
import LandingSingleProduct from "./components/shopifastlanding/rsSingleproduct/rsSingleProduct";

const SearchResults = React.lazy(() =>
  import("./components/home/search-results")
);
// const RsProducts = React.lazy(() =>

// );
const ShoppingCart = React.lazy(() =>
  import("./components/home/shopping-cart")
);
//  43 new line
const WholesalerDashBoard = React.lazy(() =>
  import("./components/wholesaleDashboard/src/App")
);
const ResslerDashBoard = React.lazy(() =>
  import("./components/resellerDashboard/src/App")
);

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { showLoginModal, showRegisterModal, isLoggedIn } = useSelector(
    (state) => {
      return state?.app;
    }
  );

  const [showCartDrawer, setShowCartDrawer] = useState(false);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDataStringified = localStorage.getItem(
    "tajira-website-logged-in-user-data"
  );

  const onCloseLoginModal = () => {
    dispatch(setShowLoginModal(false));
  };

  const onCloseRegisterModal = () => {
    dispatch(setShowRegisterModal(false));
  };
  let { path, url } = useRouteMatch();
  const locat = useLocation();
  let dashboardpath = locat.pathname.split("/");

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/" component={Shopifastlanding} />
        {dashboardpath[1] != "wholesalerdashboard" &&
          dashboardpath[1] != "resellerdashboard" &&
          dashboardpath[1] != "" &&
          dashboardpath[1] !=
            "product" &&(
              <div id="base-component" className="base-component">
                {loading && <Spinner title="Loading Products..." />}
                <div className="sticky-header">
                  <Header
                    setShowCartDrawer={() => {
                      if (isLoggedIn) {
                        setShowCartDrawer(true);
                      } else {
                        dispatch(setShowLoginModal(true));
                      }
                    }}
                  />
                  <MobileHeader
                    showMenuSideDrawer={() =>
                      setShowMenuDrawer(!showMenuDrawer)
                    }
                    setShowCartDrawer={() => {
                      if (isLoggedIn) {
                        setShowCartDrawer(true);
                      } else {
                        dispatch(setShowLoginModal(true));
                      }
                    }}
                  />
                </div>

                <Route exact path="/:shopName" component={RouteHandler} />
                <Route
                  exact
                  path="/:shopName/shopping-cart"
                  component={ShoppingCart}
                />

                <Route exact path="/:shopName/check-out" component={Checkout} />

                <Route exact path="/:shopName/product" component={RsProducts} />

                <Route
                  exact
                  path="/:shopName/payment_success"
                  component={Successpayment}
                />

                <Route
                  exact
                  path="/:shopName/payment_cancel"
                  component={CancelPayment}
                />

                <Route
                  exact
                  path="/:shopName/product/:productid"
                  component={RsSingleProduct}
                />

                <MenuSideBar
                  showSidebar={showMenuDrawer}
                  onCloseSidebar={() => setShowMenuDrawer(false)}
                />
                <Login showLogin={showLoginModal} onClose={onCloseLoginModal} />
                <Register
                  showRegister={showRegisterModal}
                  onClose={onCloseRegisterModal}
                />

                <Footer />
              </div>
            )}

        <Route
          exact
          path="/product/:productid"
          component={LandingSingleProduct}
        />

        <Route
          exact
          path="/resellerdashboard/:id"
          component={ResslerDashBoard}
        />
        <Route
          exact
          path="/wholesalerdashboard/:id"
          component={WholesalerDashBoard}
        />
      </Switch>
    </Suspense>
  );
};

export default App;

// const CommonMenu = React.lazy(() => import("./components/common-menu"));
// const Home = React.lazy(() => import("./components/home"));
// const Sliders = React.lazy(() => import("./components/home/banner"));
// const ProductsGallery = React.lazy(() =>
//   import("./components/home/products-gallery")
// );
// const Product = React.lazy(() =>
//   import("./components/poshcart/product/product")
// );
// const SingleProduct = React.lazy(() =>
//   import("./components/poshcart/singleProduct/singleProduct")
// );
// const WholeSellerProduct = React.lazy(() =>
//   import("./components/wholeSeller/product/product")
// );
// const ResellerLanding = React.lazy(() =>
//   import("./components/reseller/landing")
// );
// const Checkout = React.lazy(() => import("./components/home/checkout"));
// const OrderConfirm = React.lazy(() =>
//   import("./components/home/order-confirm")
// );
// const ContactUs = React.lazy(() => import("./components/home/contact-us"));
// const AllShops = React.lazy(() => import("./components/home/all-shops"));
// const AboutUs = React.lazy(() => import("./components/home/about-us"));
// const Blogs = React.lazy(() => import("./components/home/tajira-blogs"));
// const AllComments = React.lazy(() =>
//   import("./components/home/tajira-blogs/all-comments")
// );
// const Messages = React.lazy(() => import("./components/home/messages"));
// const Campaigns = React.lazy(() => import("./components/home/campaign"));
// const FindSimilar = React.lazy(() => import("./components/home/find-similar"));
// const TermsAndConditions = React.lazy(() =>
//   import("./components/home/terms-and-conditions")
// );
// const TrackOrder = React.lazy(() =>
//   import("./components/home/account/track-order")
// );
// const ResellerProductUpload = React.lazy(() =>
//   import("./components/reseller/productUpload/productUpload")
// );
// const LandingPage = React.lazy(() =>
//   import("./components/poshcart/landingPage/landingPage")
// );
// const ResellerRegister = React.lazy(() =>
//   import("./components/home/reseller-register")
// );
// const WholesellerRegister = React.lazy(() =>
//   import("./components/home/wholeseller-register")
// );
// const ProductFullDetails = React.lazy(() =>
//   import("./components/home/product-full-detail")
// );
{
  /* <Route exact path="/special-offer" component={SearchResults} /> */
}
{
  /* <Route exact path="/register" component={Register} /> */
}
{
  /* <Route exact path="/poshcartproduct" component={Product} /> */
}
{
  /* <Route
              exact
              path="/poshcartsingleproduct"
              component={SingleProduct}
            /> */
}
{
  /* <Route
              exact
              path="/wholeSellerProduct"
              component={WholeSellerProduct}
            /> */
}
{
  /* <Route
              exact
              path="/resellerlandingpage"
              component={ResellerLanding}
            /> */
}
{
  /* <Route
              exact
              path="/resellerproductupload"
              component={ResellerProductUpload}
            /> */
}
{
  /* <Route exact path="/resellerproduct" component={RsProducts} /> */
}
{
  /* <Route exact path="/landingPage" component={LandingPage} />
            <Route
              exact
              path="/wholesale-product-upload"
              component={ProductUpload}
            /> */
}

{
  /* <Route
              exact
              path="/resellerregister/lfjsf654gsg54dg5g4"
              component={ResellerRegister}
            />
            <Route
              exact
              path="/wholesellregister/g7rer89w878w"
              component={WholesellerRegister}
            /> */
}

{
  /* <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/order-confirm" component={OrderConfirm} /> */
}
{
  /* <Route exact path="/contact-us" component={ContactUs} /> */
}
{
  /* <Route exact path="/about-us" component={AboutUs} /> */
}
{
  /* <Route exact path="/blogs" component={Blogs} /> */
}

{
  /*--------------------------------- not included route in the routehandler file ---------------------------- */
}

{
  /* <Route exact path="/allcomments" component={AllComments} />
            <Route exact path="/all-messages" component={Messages} />
            <Route exact path="/all-messages/:storeId" component={Messages} />
            <Route exact path="/campaigns" component={Campaigns} />
            <Route exact path="/find-similar/:id" component={FindSimilar} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route
              exact
              path="/tracking-order/:trackingId"
              component={TrackOrder}
            /> */
}

{
  /* <Home
           showCartDrawer={showCartDrawer}
           setShowCartDrawer={setShowCartDrawer}
           openLoginModal={() => dispatch(setShowLoginModal(true))}
         /> */
}
{
  /* <Route exact path="/brands" component={Brands} /> */
}
{
  /* {!showMenuDrawer && <CommonMenu />} */
}
{
  /* {isHome && <Sliders />} */
}
{
  /* <Route exact path="/search-results" component={SearchResults} /> */
}

//         <Route exact path="/brand/:brandId" component={SearchResults} />

//         <Route
//         exact
//         path="/account/:section/order/:orderId"
//         component={Account}
//       />

//       <Route
//       exact
//       path="/account/:section/:orderId"
//       component={Account}
//     />

//     <Route exact path="/account/:section" component={Account} />

//     <Route
//     exact
//     path="/product/:productId"
//     component={ProductFullDetails}
//   />

//   <Route
//   exact
//   path="/category/:categoryId"
//   component={SearchResults}
// />

// <Route exact path="/shop/:shopId" component={ShopHome} />

{
  /* <Route exact path="/brands" component={Brands} /> */
}
